<template>
  <section>
    <h2 class="mb-4">Nuovo Progetto</h2>

    <div>
      <form-wizard ref="projectWizard" color="#018001" :title="null" :subtitle="null" shape="square"
        finish-button-text="Conferma" back-button-text="Indietro" next-button-text="Avanti" class="mb-3">

        <wizard-step slot-scope="props" slot="step" :tab="props.tab" :transition="props.transition"
          :index="props.index">
        </wizard-step>

        <!-- Anagrafica Cliente -->
        <tab-content title="Anagrafica Cliente" :before-change="validationCustomer">
          <!--  -->

          <validation-observer ref="customerTab">
            <b-row class="my-2">
              <b-col md="6">
                <b-form-group label="Nome Progetto" for="project_name">
                  <validation-provider #default="{ errors }" name="Nome Progetto" rules="">
                    <b-form-input id="project_name" v-model="project_name" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_name"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <hr />

            <b-row class="my-2">
              <b-col md="6">
                <b-form-group label="Ragione Sociale" for="project_customer_companyname">
                  <validation-provider #default="{ errors }" name="Ragione Sociale" rules="required">
                    <b-form-input id="project_customer_companyname" v-model="project_customer_companyname"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_customer_companyname"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="6">
                <b-form-group label="Partita IVA" for="project_customer_vatnumber">
                  <validation-provider #default="{ errors }" name="Partita IVA" rules="required">
                    <b-form-input id="project_customer_vatnumber" v-model="project_customer_vatnumber"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_customer_vatnumber"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="6">
                <b-form-group label="Nome Amministratore/Referente" for="project_customer_ceo_name">
                  <validation-provider #default="{ errors }" name="Nome Amministratore/Referente" rules="">
                    <b-form-input id="project_customer_ceo_name" v-model="project_customer_ceo_name"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_customer_ceo_name"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="6">
                <b-form-group label="Cognome Amministratore/Referente" for="project_customer_ceo_surname">
                  <validation-provider #default="{ errors }" name="Cognome Amministratore/Referente" rules="">
                    <b-form-input id="project_customer_ceo_surname" v-model="project_customer_ceo_surname"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_customer_ceo_surname"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <b-row class="my-5">
              <b-col md="3"></b-col>
              <b-col md="3" class="d-flex justify-content-center align-items-center">
                <validation-provider #default="{ errors }" name="photo_logo">
                  <p class="mr-2">Logo Cliente</p>
                  <b-form-file class="d-none" ref="logo_photo" accept="image/*" v-model="project_customer_logo"
                    @input="uploadLogo(project_customer_logo);">
                  </b-form-file>
                  <b-button :class="project_customer_logo ? 'd-none' : 'd-block'" variant="outline-primary" pill
                    @click="$refs.logo_photo.$el.childNodes[0].click()">
                    Carica il file
                  </b-button>

                  <div v-if="uploadLogoLoading">
                    <b-spinner variant="primary" class="ml-1" />
                  </div>

                  <div v-if="project_customer_logo">
                    <img height="100" :src="project_customer_logo_url">
                  </div>

                  <b-button class="mt-2" :class="project_customer_logo ? 'd-block' : 'd-none'" variant="outline-primary"
                    pill @click="$refs.logo_photo.$el.childNodes[0].click()">
                    Sostituisci il file
                  </b-button>


                  <div>
                    <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                  </div>
                </validation-provider>
              </b-col>
              <b-col md="3" class="d-flex justify-content-center align-items-center">
                <validation-provider #default="{ errors }" name="building_logo">
                  <p class="mr-2">Foto Stabilimento</p>
                  <b-form-file class="d-none" ref="building_photo" accept="image/*" v-model="project_building_photo"
                    @input="uploadBuildingPhoto(project_building_photo);">
                  </b-form-file>
                  <b-button :class="project_building_photo ? 'd-none' : 'd-block'" variant="outline-primary" pill
                    @click="$refs.building_photo.$el.childNodes[0].click()">
                    Carica il file
                  </b-button>

                  <div v-if="uploadBuildingPhotoLoading">
                    <b-spinner variant="primary" class="ml-1" />
                  </div>

                  <div v-if="project_building_photo">
                    <img height="100" :src="project_building_photo_url">
                  </div>

                  <b-button class="mt-2" :class="project_building_photo ? 'd-block' : 'd-none'"
                    variant="outline-primary" pill @click="$refs.building_photo.$el.childNodes[0].click()">
                    Sostituisci il file
                  </b-button>

                  <div>
                    <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                  </div>
                </validation-provider>
              </b-col>
              <b-col md="3"></b-col>
            </b-row>

            <b-row class="my-2">
              <b-col md="4">
                <b-form-group label="Indirizzo (sede legale)" for="project_customer_address">
                  <validation-provider #default="{ errors }" name="Indirizzo (sede legale)" rules="required">
                    <b-form-input id="project_customer_address" :state="errors.length > 0 ? false : null"
                      v-model="project_customer_address" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="8">
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Città (sede legale)" for="project_customer_city">
                      <validation-provider #default="{ errors }" name="Città (sede legale)" rules="required">
                        <b-form-input id="project_customer_city" v-model="project_customer_city"
                          :state="errors.length > 0 ? false : null" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="CAP (sede legale)" for="project_customer_zipcode">
                      <validation-provider #default="{ errors }" name="CAP (sede legale)" rules="required">
                        <b-form-input id="project_customer_zipcode" :state="errors.length > 0 ? false : null"
                          v-model="project_customer_zipcode" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="Provincia (sede legale)" for="project_customer_province">
                      <validation-provider #default="{ errors }" name="Provincia (sede legale)" rules="required">
                        <b-form-select :items="provinces" v-model="project_customer_province"
                          :state="errors.length > 0 ? false : null">
                          <b-form-select-option value="" disabled>Seleziona Provincia</b-form-select-option>
                          <b-form-select-option v-for="province in provinces" :key="province.geo_code"
                            :value="province.geo_code">{{ province.geo_name }}</b-form-select-option>
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="Nazione (sede legale)" for="project_customer_country">
                      <validation-provider #default="{ errors }" name="Nazione (sede legale)" rules="required">
                        <b-form-input id="project_customer_country" :state="errors.length > 0 ? false : null"
                          v-model="project_customer_country" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <hr />

            <b-row class="justify-content-center my-2">
              <b-col md="4">
                <b-form-group for="project_siteinspection">
                  <span class="mr-2">Sopralluogo effettuato?</span>
                  <validation-provider name="Sopralluogo Effettuato?">
                    <b-form-radio-group v-model="project_siteinspection">
                      <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                      <b-form-radio name="No" value="No">No</b-form-radio>
                    </b-form-radio-group>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group v-if="project_siteinspection == 'Yes'" label="Data Sopralluogo" for="inspection_date">
                  <validation-provider v-if="project_siteinspection == 'Yes'" #default="{ errors }"
                    name="Data Sopralluogo" rules="required">
                    <b-form-input :disabled="project_siteinspection == 'No'" v-model="project_siteinspection_date"
                      id="inspection_date" :state="errors.length > 0 ? false : null" type="date"
                      placeholder="AAAA-MM-GG" />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="12">
                <b-form-group label="Descrizione attitivà e storia del cliente" for="project_customer_about">
                  <validation-provider #default="{ errors }" name="Descrizione attività e storia del cliente" rules="">
                    <b-form-textarea id="project_customer_about" :rows="8" :state="errors.length > 0 ? false : null"
                      placeholder="Scrivi..." v-model="project_customer_about" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <a href="#" @click="selected_help_field = help_field.project_customer_about"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group label="Descrizione intervento" for="project_customer_desc_intervention">
                  <validation-provider #default="{ errors }" rules="" name="Descrizione intervento">
                    <b-form-textarea id="project_customer_desc_intervention"
                      v-model="project_customer_desc_intervention" :rows="8" :state="errors.length > 0 ? false : null"
                      placeholder="Scrivi..." />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <a href="#" @click="selected_help_field = help_field.project_customer_desc_intervention"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group label="Tipologia di intervento" for="project_customer_type_intervention">
                  <validation-provider #default="{ errors }" name="Tipologia di intervento" rules="">
                    <b-form-textarea id="project_customer_type_intervention" :rows="8"
                      :state="errors.length > 0 ? false : null" placeholder="Scrivi"
                      v-model="project_customer_type_intervention" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <a href="#" @click="selected_help_field = help_field.project_customer_type_intervention"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <hr />

            <b-row class="my-2">
              <b-col md="6">
                <b-form-group label="Nome tecnico incaricato 1" for="project_technical_1_name">
                  <validation-provider #default="{ errors }" name="Nome tecnico incaricato 1" rules="">
                    <b-form-input id="project_technical_1_name" v-model="project_technical_1_name"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_technical"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="6">
                <b-form-group label="Cognome tecnico incaricato 1" for="project_technical_1_surname">
                  <validation-provider #default="{ errors }" name="Cognome tecnico incaricato 1" rules="">
                    <b-form-input id="project_technical_1_surname" v-model="project_technical_1_surname"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_technical"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="6">
                <b-form-group label="Indirizzo e-mail tecnico incaricato 1 (Opzionale)" for="project_technical_1_email">
                  <validation-provider #default="{ errors }" name="Indirizzo e-mail tecnico incaricato 1" rules="email">
                    <b-form-input type="email" id="project_technical_1_email" v-model="project_technical_1_email"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_technical"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <hr />

            <b-row class="my-2">
              <b-col md="6">
                <b-form-group label="Nome tecnico incaricato 2 (Opzionale)" for="project_technical_2_name">
                  <validation-provider #default="{ errors }" name="Nome tecnico incaricato 2">
                    <b-form-input id="project_technical_2_name" v-model="project_technical_2_name"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_technical"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="6">
                <b-form-group label="Cognome tecnico incaricato 2 (Opzionale)" for="project_technical_2_surname">
                  <validation-provider #default="{ errors }" name="Cognome tecnico incaricato 2">
                    <b-form-input id="project_technical_2_surname" v-model="project_technical_2_surname"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_technical"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>


              <b-col md="6">
                <b-form-group label="Indirizzo e-mail tecnico incaricato 2 (Opzionale)" for="project_technical_2_email">
                  <validation-provider #default="{ errors }" name="Indirizzo e-mail tecnico incaricato 2" rules="email">
                    <b-form-input type="email" id="project_technical_2_email" v-model="project_technical_2_email"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_technical"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <hr />

            <fieldset class="border p-1 bg-light">
              <legend class="text-center mx-auto bg-white border border-dark px-2"
                style="width: min-content; white-space: nowrap">Sito Intervento</legend>
              <div class="d-flex justify-content-center">
                <a href="#" @click="selected_help_field = help_field.project_siteinspection"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </div>
              <b-row class="my-2">
                <b-col md="4">
                  <b-form-group label="Indirizzo" for="project_siteinspection_address">
                    <validation-provider #default="{ errors }" name="Indirizzo (Sito Intervento)" rules="required">
                      <b-form-input id="project_siteinspection_address" v-model="project_siteinspection_address"
                        :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="8">
                  <b-row>
                    <b-col md="3">
                      <b-form-group label="Città" for="project_siteinspection_city">
                        <validation-provider #default="{ errors }" name="Città (Sito Intervento)" rules="required">
                          <b-form-input id="project_siteinspection_city" v-model="project_siteinspection_city"
                            :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group label="CAP" for="project_siteinspection_zipcode">
                        <validation-provider #default="{ errors }" name="CAP (Sito Intervento)" rules="required">
                          <b-form-input id="project_siteinspection_zipcode" v-model="project_siteinspection_zipcode"
                            :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group label="Provincia" for="project_siteinspection_province">
                        <validation-provider #default="{ errors }" name="Provincia (Sito Intervento)" rules="required">
                          <b-form-select :items="provinces" v-model="project_siteinspection_province"
                            :state="errors.length > 0 ? false : null">
                            <b-form-select-option value="" disabled>Seleziona Provincia</b-form-select-option>
                            <b-form-select-option v-for="province in provinces" :key="province.geo_code"
                              :value="province.geo_code">{{ province.geo_name }}</b-form-select-option>
                          </b-form-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group label="Nazione" for="project_siteinspection_country">
                        <validation-provider #default="{ errors }" name="Nazione (Sito Intervento)" rules="required">
                          <b-form-input id="project_siteinspection_country" v-model="project_siteinspection_country"
                            :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <hr />

              <b-row class="my-3">
                <b-col md="4">
                  <p class="mr-2 mb-2">Foto 1</p>
                  <validation-provider #default="{ errors }" name="photo_1">
                    <b-form-file ref="attachment_photo_1" class="d-none" v-model="project_siteinspection_photo_1"
                      @input="uploadPhoto1(project_siteinspection_photo_1)">
                    </b-form-file>
                    <b-button v-if="!project_siteinspection_photo_1" variant="outline-primary" pill
                      @click="$refs.attachment_photo_1.$el.childNodes[0].click()">
                      Carica il file</b-button>

                    <div v-if="uploadPhoto1Loading">
                      <b-spinner variant="primary" class="ml-1" />
                    </div>

                    <div v-if="project_siteinspection_photo_1">
                      <img height="100" :src="project_siteinspection_photo_1_url">
                    </div>

                    <b-button class="mt-2" :class="project_siteinspection_photo_1 ? 'd-block' : 'd-none'"
                      variant="outline-primary" pill @click="$refs.attachment_photo_1.$el.childNodes[0].click()">
                      Sostituisci il file</b-button>

                    <div>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </div>
                  </validation-provider>
                </b-col>

                <b-col md="4">
                  <p class="mr-2 mb-2">Foto 2</p>
                  <validation-provider #default="{ errors }" name="photo_2">
                    <b-form-file ref="attachment_photo_2" class="d-none" v-model="project_siteinspection_photo_2"
                      @input="uploadPhoto2(project_siteinspection_photo_2)">
                    </b-form-file>
                    <b-button v-if="!project_siteinspection_photo_2" variant="outline-primary" pill
                      @click="$refs.attachment_photo_2.$el.childNodes[0].click()">
                      Carica il file</b-button>

                    <div v-if="uploadPhoto2Loading">
                      <b-spinner variant="primary" class="ml-1" />
                    </div>

                    <div v-if="project_siteinspection_photo_2">
                      <img height="100" :src="project_siteinspection_photo_2_url">
                    </div>

                    <b-button class="mt-2" :class="project_siteinspection_photo_2 ? 'd-block' : 'd-none'"
                      variant="outline-primary" pill @click="$refs.attachment_photo_2.$el.childNodes[0].click()">
                      Sostituisci il file</b-button>

                    <div>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </div>
                  </validation-provider>

                </b-col>

                <b-col md="4">
                  <p class="mr-2 mb-2">Planimetria</p>
                  <validation-provider #default="{ errors }" name="photo_attachment">
                    <b-form-file ref="attachment_file_input" class="d-none" v-model="project_siteinspection_attachment"
                      @input="uploadAttachment(project_siteinspection_attachment)">
                    </b-form-file>

                    <b-button v-if="!project_siteinspection_attachment" variant="outline-primary" pill
                      @click="$refs.attachment_file_input.$el.childNodes[0].click()">Carica il file</b-button>

                    <div v-if="uploadAttachmentLoading">
                      <b-spinner variant="primary" class="ml-1" />
                    </div>

                    <div v-if="project_siteinspection_attachment">
                      <img height="100" :src="project_siteinspection_attachment_url">
                    </div>

                    <b-button class="mt-2" :class="project_siteinspection_attachment ? 'd-block' : 'd-none'"
                      variant="outline-primary" pill @click="$refs.attachment_file_input.$el.childNodes[0].click()">
                      Sostituisci il file</b-button>

                    <div>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </div>
                  </validation-provider>
                </b-col>
              </b-row>
            </fieldset>

            <hr />

            <b-row>
              <b-col md="4">
                <b-form-group label="Dettagli impianto" for="project_implant_details">
                  <validation-provider #default="{ errors }" name="Dettagli impianto" rules="">
                    <b-form-textarea id="project_implant_details" :rows="8" :state="errors.length > 0 ? false : null"
                      placeholder="Scrivi" v-model="project_implant_details" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_implant_details"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="4">
                <b-form-group label="Scopo di utilizzo" for="project_implant_scope">
                  <validation-provider #default="{ errors }" name="Scopo di Utilizzo" rules="required">
                    <b-form-select id="project_implant_scope" :options="implant_scope_items"
                      :state="errors.length > 0 ? false : null" placeholder="Scrivi" v-model="project_implant_scope" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_implant_scope"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>

                <b-form-group v-if="userData && userData.account_type_code != 'utilizzatore' && userData.account_type_code != 'installatore'"
                  label="È prevista attività di manutenzione/riparazione/recupero e riutilizzo o smaltimento di fluido refrigerante o parti di impianto?">
                  <validation-provider #default="{ errors }" :name="'question_5'" rules="required">
                    <b-form-radio-group :id="'question_5'" v-model="question_5"
                      :state="errors.length > 0 ? false : null">
                      <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                      <b-form-radio name="No" value="No">No</b-form-radio>
                    </b-form-radio-group>
                    <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                  </validation-provider>
                </b-form-group>
                <a v-if="userData.account_type_code != 'utilizzatore' && userData.account_type_code != 'installatore'" href="#" @click="selected_help_field = help_field.question_5"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>

                <b-form-group label="È presente una pista in ghiaccio? (Es. pista di pattinaggio sul ghiaccio)">
                  <validation-provider #default="{ errors }" :name="'question_0'" rules="required">
                    <b-form-radio-group :id="'question_0'" v-model="question_0"
                      :state="errors.length > 0 ? false : null">
                      <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                      <b-form-radio name="No" value="No">No</b-form-radio>
                    </b-form-radio-group>
                    <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.question_0"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="4"  v-if="userData.account_type_code == 'installatore_manutentore'">
                <b-form-group label="Definizione ruolo utente" for="project_user_role">
                  <validation-provider #default="{ errors }" name="Definizione Ruolo Utente" rules="required">
                    <b-form-select
                      :disabled="['installatore', 'manutentore', 'utilizzatore'].includes(userData.account_type_code)"
                      id="project_user_role" :options="userRoles" v-model="project_user_role"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_user_role" hidden
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>

                <b-form-group label="Si interviene su impianto" for="project_type" hidden>
                  <validation-provider #default="{ errors }" name="Si interviene su impianto" rules="required">
                    <b-form-select disabled id="project_type" :options="implantTypes" v-model="project_type"
                      :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.project_type" hidden
                  class="d-none align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Descrizione Impianto -->
        <tab-content title="Descrizione Impianto" :before-change="validationFormInfo">
          <!--  -->

          <validation-observer ref="infoRules" tag="form">
            <b-row>
              <b-col md="4">
                <b-form-group for="project_access_category">
                  <validation-provider #default="{ errors }" name="Categoria di accesso" rules="required">
                    <h3>Categoria di accesso:</h3>
                    <b-form-select id="project_access_category" :options="access_categories_items"
                      :state="errors.length > 0 ? false : null" v-model="project_access_category" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <b-alert v-b-modal.modal-1 class="mt-2 p-1 cursor-pointer" variant="success" show>
                      <i class="feather icon-info mr-1" variant="success"></i>Guida alla compilazione
                    </b-alert>
                    <p>N.B.: in caso di più categorie di accesso, indicare quella più stringente (1 – restrizione bassa;
                      2 - restrizione media; Restrizione alta)</p>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="Allocazione Impianto" rules="required">
                    <h3>Allocazione impianto:</h3>
                    <b-form-select id="project_plant_allocation" :options="allocation_items"
                      v-model="project_plant_allocation" :state="errors.length > 0 ? false : null"
                      @input="changeInstallationTypeItems" />
                    <small class="text-danger">{{ errors[0] }}</small>

                    <b-alert v-b-modal.modal-2 class="mt-2 p-1 cursor-pointer" variant="success" show>
                      <i class="feather icon-info mr-1" variant="success" />Guida alla compilazione
                    </b-alert>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="Tipologia Impianto" rules="required">
                    <h3>Tipologia impianto:</h3>
                    <b-form-select id="project_plant_type" :options="installation_type_items"
                      :state="errors.length > 0 ? false : null" v-model="project_plant_type"
                      :disabled="!project_plant_allocation" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <b-alert v-b-modal.modal-3 class="mt-2 p-1 cursor-pointer" variant="success" show>
                      <i class="feather icon-info mr-1" variant="success" />Guida alla compilazione
                    </b-alert>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="4"></b-col>

              <b-col md="4">
                <b-form-group label="N° di ambienti refrigerati / celle">
                  <validation-provider #default="{ errors }" name="Numero Ambienti/Celle" rules="required|between:1,5">
                    <b-input-group>
                      <b-form-input ref="cellNumber" @keydown.enter.prevent="
                        cellNumber <= 5
                          ? $bvModal.show('modal-impianti')
                          : null
                      " id="cell_number" v-model.number="cellNumber" type="number">
                      </b-form-input>

                      <b-input-group-append>
                        <b-button variant="outline-primary" @click="$bvModal.show('modal-impianti')">Conferma</b-button>
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.envs_number"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>

              <b-col md="4"></b-col>
            </b-row>

            <hr />

            <!-- Form Ripetibile -->
            <b-row v-if="cellsToGenerate <= 0">
              <b-col md="12">Inserisci un numero di ambienti o celle refrigerate</b-col>
            </b-row>
            <b-tabs v-model="tabIndex" vertical>

              <b-tab v-for="(i, index) in Array(cellsToGenerate).fill().map((e, i) => i + 1)" :key="i"
                title-item-class="disabledTab">

                <template #title>
                  <span ref="checkIcon" class="mr-1" />
                  <span>Ambiente Refrigerato/Cella: {{ nameList[i - 1] }}</span>
                </template>
                <validation-observer ref='tabRules'>
                  <b-row>
                    <b-col md="3">
                      <b-form-group label="Superficie (m²)" :for="'cell_surface_' + [i]">
                        <validation-provider #default="{ errors }" :name="'Superficie_' + [i]" rules="required">
                          <b-form-input type="number" :id="'cell_surface_' + [i]" v-model="cells[i - 1].cell_surface"
                            @input="cells[i - 1].cell_volume = cells[i - 1].cell_surface * cells[i - 1].cell_height"
                            :state="errors.length > 0 ? false : null"></b-form-input>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.cell_surface"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Altezza (m)" :for="'cell_height_' + [i]">
                        <validation-provider #default="{ errors }" :name="'Altezza_' + [i]" rules="required">
                          <b-form-input type="number" :id="'cell_height_' + [i]" v-model="cells[i - 1].cell_height"
                            @input="cells[i - 1].cell_volume = cells[i - 1].cell_surface * cells[i - 1].cell_height"
                            :state="errors.length > 0 ? false : null"></b-form-input>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                      </b-form-group>

                      <a href="#" @click="selected_help_field = help_field.cell_height"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Volume (m³)" :for="'cell_volume_' + [i]">
                        <validation-provider #default="{ errors }" :name="'Volume_' + [i]" rules="required">
                          <b-form-input type="number" :id="'cell_volume_' + [i]"
                            :value="cells[i - 1].cell_surface * cells[i - 1].cell_height"
                            :state="errors.length > 0 ? false : null" readonly />
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Temperatura (°C)" :for="'cell_temperature_' + [i]">
                        <validation-provider #default="{ errors }" :name="'Temperatura_' + [i]" rules="required">
                          <b-form-input type="number" :id="'cell_temperature_' + [i]"
                            v-model="cells[i - 1].cell_temperature" :state="errors.length > 0 ? false : null">
                          </b-form-input>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                      </b-form-group>

                      <a href="#" @click="selected_help_field = help_field.cell_temperature"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>
                  </b-row>

                  <!-- <fieldset class="border p-1 bg-light my-2">
                    <legend class="bg-white border border-dark px-2" style="width: min-content; white-space: nowrap">
                      Unità Condensatrice
                    </legend>
                    <b-row>
                      <b-col md="4">
                        <validation-provider #default="{ errors }" :name="'Marca Condensatrice' + [i]">
                          <b-form-group label="Marca">
                            <b-form-input :id="'condenser_brand_' + [i]" v-model="cells[i - 1].condenser_brand"
                              :state="errors.length > 0 ? false : null"></b-form-input>
                          </b-form-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>

                        <a href="#" @click="selected_help_field = help_field.condenser_brand"
                          class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                          <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                          <p class="m-0"><b>Guida alla compilazione</b></p>
                        </a>
                      </b-col>
                      <b-col md="4">
                        <validation-provider #default="{ errors }" :name="'Modello Condensatrice' + [i]">
                          <b-form-group label="Modello">
                            <b-form-input :id="'condenser_model_' + [i]" v-model="cells[i - 1].condenser_model"
                              :state="errors.length > 0 ? false : null"></b-form-input>
                          </b-form-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>

                        <a href="#" @click="selected_help_field = help_field.condenser_model"
                          class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                          <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                          <p class="m-0"><b>Guida alla compilazione</b></p>
                        </a>
                      </b-col>
                      <b-col md="4">
                        <validation-provider #default="{ errors }" :name="'Luogo di installazione Condensatrice' + [i]">
                          <b-form-group label="Luogo di installazione">
                            <b-form-input :id="'condenser_place_' + [i]" v-model="cells[i - 1].condenser_place"
                              :state="errors.length > 0 ? false : null"></b-form-input>
                          </b-form-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>

                        <a href="#" @click="selected_help_field = help_field.condenser_place"
                          class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                          <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                          <p class="m-0"><b>Guida alla compilazione</b></p>
                        </a>
                      </b-col>
                    </b-row>
                  </fieldset>

                  <fieldset class="border p-1 bg-light my-2">
                    <legend class="bg-white border border-dark px-2" style="width: min-content; white-space: nowrap">
                      Scambiatore</legend>

                    <b-row>
                      <b-col md="4">
                        <validation-provider #default="{ errors }" :name="'Marca Scambiatore' + [i]">
                          <b-form-group label="Marca">
                            <b-form-input :id="'exchanger_brand_' + [i]" v-model="cells[i - 1].exchanger_brand"
                              :state="errors.length > 0 ? false : null"></b-form-input>
                          </b-form-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                        <a href="#" @click="selected_help_field = help_field.exchanger_brand"
                          class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                          <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                          <p class="m-0"><b>Guida alla compilazione</b></p>
                        </a>
                      </b-col>
                      <b-col md="4">
                        <validation-provider #default="{ errors }" :name="'Modello Scambiatore' + [i]">
                          <b-form-group label="Modello">
                            <b-form-input :id="'exchanger_model_' + [i]" v-model="cells[i - 1].exchanger_model"
                              :state="errors.length > 0 ? false : null"></b-form-input>
                          </b-form-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>

                        <a href="#" @click="selected_help_field = help_field.exchanger_model"
                          class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                          <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                          <p class="m-0"><b>Guida alla compilazione</b></p>
                        </a>
                      </b-col>
                      <b-col md="4">
                        <validation-provider #default="{ errors }" :name="'Luogo di installazione Scambiatore' + [i]">
                          <b-form-group label="Luogo di installazione">
                            <b-form-input :id="'exchanger_place_' + [i]" v-model="cells[i - 1].exchanger_place"
                              :state="errors.length > 0 ? false : null"></b-form-input>
                          </b-form-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>

                        <a href="#" @click="selected_help_field = help_field.exchanger_place"
                          class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                          <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                          <p class="m-0"><b>Guida alla compilazione</b></p>
                        </a>
                      </b-col>
                    </b-row>
                  </fieldset>

                  <fieldset class="border p-1 bg-light my-2">
                    <legend class="bg-white border border-dark px-2" style="width: min-content; white-space: nowrap">
                      Compressore</legend>

                    <b-row>
                      <b-col md="4">
                        <validation-provider #default="{ errors }" :name="'Marca Compressore' + [i]">
                          <b-form-group label="Marca">
                            <b-form-input :id="'compressor_brand_' + [i]" v-model="cells[i - 1].compressor_brand"
                              :state="errors.length > 0 ? false : null"></b-form-input>
                          </b-form-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>

                        <a href="#" @click="selected_help_field = help_field.compressor_brand"
                          class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                          <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                          <p class="m-0"><b>Guida alla compilazione</b></p>
                        </a>
                      </b-col>
                      <b-col md="4">
                        <validation-provider #default="{ errors }" :name="'Modello Compressore' + [i]">
                          <b-form-group label="Modello">
                            <b-form-input :id="'compressor_model_' + [i]" v-model="cells[i - 1].compressor_model"
                              :state="errors.length > 0 ? false : null"></b-form-input>
                          </b-form-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>

                        <a href="#" @click="selected_help_field = help_field.compressor_model"
                          class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                          <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                          <p class="m-0"><b>Guida alla compilazione</b></p>
                        </a>

                      </b-col>
                      <b-col md="4">
                        <validation-provider #default="{ errors }" :name="'Luogo di installazione Compressore' + [i]">
                          <b-form-group label="Luogo di installazione">
                            <b-form-input :id="'compressor_place' + [i]" v-model="cells[i - 1].compressor_place"
                              :state="errors.length > 0 ? false : null"></b-form-input>
                          </b-form-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>

                        <a href="#" @click="selected_help_field = help_field.compressor_place"
                          class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                          <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                          <p class="m-0"><b>Guida alla compilazione</b></p>
                        </a>

                      </b-col>
                    </b-row>
                  </fieldset>

                  <fieldset class="border p-1 bg-light my-2">
                    <legend class="bg-white border border-dark px-2" style="width: min-content; white-space: nowrap">
                      Evaporatore</legend>

                    <b-row>
                      <b-col md="4">
                        <validation-provider #default="{ errors }" :name="'Marca' + [i]">
                          <b-form-group label="Marca Evaporatore">
                            <b-form-input :id="'evaporator_brand_' + [i]" v-model="cells[i - 1].evaporator_brand"
                              :state="errors.length > 0 ? false : null"></b-form-input>
                          </b-form-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>

                        <a href="#" @click="selected_help_field = help_field.evaporator_brand"
                          class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                          <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                          <p class="m-0"><b>Guida alla compilazione</b></p>
                        </a>


                      </b-col>
                      <b-col md="4">
                        <validation-provider #default="{ errors }" :name="'Modello' + [i]">
                          <b-form-group label="Modello Evaporatore">
                            <b-form-input :id="'evaporator_model_' + [i]" v-model="cells[i - 1].evaporator_model"
                              :state="errors.length > 0 ? false : null"></b-form-input>
                          </b-form-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>

                        <a href="#" @click="selected_help_field = help_field.evaporator_model"
                          class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                          <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                          <p class="m-0"><b>Guida alla compilazione</b></p>
                        </a>


                      </b-col>
                      <b-col md="4">
                        <validation-provider #default="{ errors }" :name="'Luogo di installazione Evaporatore' + [i]">
                          <b-form-group label="Luogo di installazione">
                            <b-form-input :id="'evaporator_place' + [i]" v-model="cells[i - 1].evaporator_place"
                              :state="errors.length > 0 ? false : null"></b-form-input>
                          </b-form-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>

                        <a href="#" @click="selected_help_field = help_field.evaporator_place"
                          class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                          <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                          <p class="m-0"><b>Guida alla compilazione</b></p>
                        </a>


                      </b-col>
                    </b-row>
                  </fieldset> -->

                  <b-row>
                    <b-col md="4">
                      <b-form-group label="Persone che possono accedere all'ambiente refrigerato in contemporanea">
                        <validation-provider #default="{ errors }" :name="'Numero di utilizzatori ' + [i]"
                          rules="required">
                          <b-input-group class="w-50">
                            <b-input-group-prepend>
                              <b-button variant="outline-primary"
                                @click="cells[i - 1].number_of_user > 1 ? cells[i - 1].number_of_user-- : null">
                                -
                              </b-button>
                            </b-input-group-prepend>
                            <b-form-input size="md" type="number" :min="1" :id="'number_of_user_' + [i]"
                              v-model="cells[i - 1].number_of_user" :state="errors.length > 0 ? false : null">
                            </b-form-input>
                            <b-input-group-append>
                              <b-button variant="outline-primary" @click="cells[i - 1].number_of_user++">
                                +
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.number_of_user"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="Si trova nel seminterrato?">
                        <validation-provider #default="{ errors }" :name="'Si trova nel seminterrato ' + [i]"
                          rules="required">
                          <b-form-radio-group :id="'question_1_' + [i]" v-model="cells[i - 1].question_1"
                            :state="errors.length > 0 ? false : null">
                            <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                            <b-form-radio name="No" value="No">No</b-form-radio>
                          </b-form-radio-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.cell_question_1"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="L'ambiente è provvisto di uscite di sicurezza?">
                        <validation-provider #default="{ errors }" :name="'Ambiente_sicuro_ ' + [i]" rules="required">
                          <b-form-radio-group :id="'question_2_' + [i]" v-model="cells[i - 1].question_2"
                            :state="errors.length > 0 ? false : null">
                            <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                            <b-form-radio name="No" value="No">No</b-form-radio>
                          </b-form-radio-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.cell_question_2"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>

                    <b-col md="4">
                      <b-form-group :label="'Ci sono aperture per la ventilazione naturale nell\'ambiente?'">
                        <validation-provider #default="{ errors }" :name="'Ambiente_ventilato_ ' + [i]"
                          rules="required">
                          <b-form-radio-group :id="'question_3_' + [i]" v-model="cells[i - 1].question_3"
                            :state="errors.length > 0 ? false : null">
                            <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                            <b-form-radio name="No" value="No">No</b-form-radio>
                          </b-form-radio-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.cell_question_3"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>

                    <b-col md="4" v-if="false">
                      <b-form-group label="Gli operatori possono accedere alla cella frigo?">
                        <validation-provider #default="{ errors }" :name="'operatori_' + [i]" rules="required">
                          <b-form-radio-group :id="'question_4_' + [i]" v-model="cells[i - 1].question_4"
                            :state="errors.length > 0 ? false : null">
                            <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                            <b-form-radio name="No" value="No">No</b-form-radio>
                          </b-form-radio-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.cell_question_4"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>

                    <b-col md="4" v-if="false">
                      <b-form-group label="L’impianto è di tipo ermetico?">
                        <validation-provider #default="{ errors }" :name="'giunzioni_' + [i]" rules="required">
                          <b-form-radio-group :id="'question_5_' + [i]" v-model="cells[i - 1].question_5"
                            :state="errors.length > 0 ? false : null">
                            <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                            <b-form-radio name="No" value="No">No</b-form-radio>
                          </b-form-radio-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.cell_question_5"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>

                    <b-col v-if="project_type == 1" md="4">
                      <b-form-group
                        :label="'Indicare la quantità di refrigerante presente nell\'impianto per l\'ambiente [kg]'">
                        <validation-provider #default="{ errors }" :name="'Quantita_refrigerante_ ' + [i]"
                          rules="required">
                          <b-input-group class="w-50">
                            <b-input-group-prepend>
                              <b-button variant="outline-primary"
                                @click="cells[i - 1].refrigerant_quantity > 1 ? cells[i - 1].refrigerant_quantity-- : null">
                                -
                              </b-button>
                            </b-input-group-prepend>
                            <b-form-input size="md" type="number" :min="1" :id="'number_of_user_' + [i]"
                              v-model="cells[i - 1].refrigerant_quantity" :state="errors.length > 0 ? false : null">
                            </b-form-input>
                            <b-input-group-append>
                              <b-button variant="outline-primary" @click="cells[i - 1].refrigerant_quantity++">
                                +
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>

                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.cell_refrigerant_quantity"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>

                    <b-col v-else-if="project_type == 2" md="4">
                      <b-form-group :label="'Indicare la quantità di refrigerante da progetto per l\'ambiente [Kg]'">
                        <validation-provider #default="{ errors }" :name="'Quantita_refrigerante_ ' + [i]"
                          rules="required">
                          <b-input-group class="w-50">
                            <b-input-group-prepend>
                              <b-button variant="outline-primary"
                                @click="cells[i - 1].refrigerant_quantity > 1 ? cells[i - 1].refrigerant_quantity-- : null">
                                -
                              </b-button>
                            </b-input-group-prepend>
                            <b-form-input size="md" type="number" :min="1" :id="'number_of_user_' + [i]"
                              v-model="cells[i - 1].refrigerant_quantity" :state="errors.length > 0 ? false : null">
                            </b-form-input>
                            <b-input-group-append>
                              <b-button variant="outline-primary" @click="cells[i - 1].refrigerant_quantity++">
                                +
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>

                          <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.cell_refrigerant_quantity"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>

                    <b-col md="4">
                      <b-form-group>
                        <slot name="label">l’ambiente considerato rispetta le prescrizioni di applicabilità delle
                          disposizioni alternative indicate in allegato C.3 della UNI EN 378-1:2021? (<a target="_blank"
                            v-bind:href="STORAGE_URL + '/prescrizioni_per_disposizioni_alternative.pdf'">Scarica
                            prescrizioni</a>)?</slot>
                        <validation-provider #default="{ errors }" :name="'disposizioni_alternative ' + [i]"
                          rules="required">
                          <b-form-radio-group :id="'question_6_' + [i]" v-model="cells[i - 1].question_6"
                            :state="errors.length > 0 ? false : null">
                            <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                            <b-form-radio name="No" value="No">No</b-form-radio>
                          </b-form-radio-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è
                            richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.prescription_respect"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>
                    <b-col md="4">
                      <b-form-group>
                        <slot name="label">L’ambiente è considerato “Spazio Occupato”?</slot>
                        <validation-provider #default="{ errors }" :name="'spazio_occupato ' + [i]" rules="required">
                          <b-form-radio-group :id="'question_7_' + [i]" v-model="cells[i - 1].question_7"
                            :state="errors.length > 0 ? false : null">
                            <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                            <b-form-radio name="No" value="No">No</b-form-radio>
                          </b-form-radio-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è
                            richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.occupied_space"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>

                    <b-col md="4" v-if="project_implant_scope == 'Condizionamento'">
                      <b-form-group>
                        <slot name="label">Posizione unità interna</slot>
                        <validation-provider #default="{ errors }" :name="'positioning ' + [i]" rules="required">
                          <b-form-select :items="internal_unit_positions" :id="'positioning_' + [i]" v-model="cells[i - 1].positioning"
                            :state="errors.length > 0 ? false : null">
                            <b-form-select-option value="" disabled>Seleziona Posizionamento</b-form-select-option>
                            <b-form-select-option v-for="position in internal_unit_positions" :key="position.value" :value="position.value">{{ position.text }}</b-form-select-option>
                          </b-form-select>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è
                            richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.positioning"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                      </a>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="4">
                      <b-form-group>
                        <slot name="label">Gli occupanti dell’ambiente in oggetto sono limitati nella loro
                          movimentazione?</slot>
                        <validation-provider #default="{ errors }" :name="'question_8_ ' + [i]" rules="required">
                          <b-form-radio-group :id="'question_8_' + [i]" v-model="cells[i - 1].question_8"
                            :state="errors.length > 0 ? false : null">
                            <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                            <b-form-radio name="No" value="No">No</b-form-radio>
                          </b-form-radio-group>
                          <small v-if="errors.length > 0" class="text-danger">Il campo è
                            richiesto</small>
                        </validation-provider>
                      </b-form-group>
                      <a href="#" @click="selected_help_field = help_field.limit_movement"
                        class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                        <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                        <p class="m-0"><b>Guida alla compilazione</b></p>
                        b-row
                      </a>
                    </b-col>
                  </b-row>

                  <div class="text-center">
                    <b-button-group class="mt-1">
                      <b-button v-if="tabIndex >= 1" class="mr-2" variant="outline-primary" @click="tabIndex--">
                        Ambiente
                        Precedente
                      </b-button>
                      <b-button v-if="tabIndex + 1 < cellNumber" variant="outline-primary"
                        @click="validationFormTabs(i - 1)">
                        Ambiente
                        Successivo</b-button>
                    </b-button-group>
                  </div>

                </validation-observer>

              </b-tab>
            </b-tabs>

          </validation-observer>

        </tab-content>

        <!-- Descrizione Impianto Pt.2 -->

        <tab-content title="Descrizione Impianto Pt.2" :before-change="validationFormDescription2">

          <validation-observer ref="description2Rules">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="All'interno dell'edificio c'è un seminterrato non asservito da impianto o parte di impianto?">
                  <validation-provider #default="{ errors }" name="radio_1" rules="required">
                    <b-form-radio-group id="question_1" v-model="question_1" :state="errors.length > 0 ? false : null">
                      <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                      <b-form-radio name="No" value="No">No</b-form-radio>
                    </b-form-radio-group>
                    <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.building_question_1"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
              <b-col  md="6" v-if="question_1 == 'Yes'">
                <b-form-group>
                  <slot name="label">Volume seminterrato non asservito all'impianto (m<sup>3</sup>)</slot>
                  <validation-provider #default="{ errors }" name="radio_1" rules="required">
                    <b-form-input class="w-50" :type="'number'" name="basement_volume" v-model="basement_volume"></b-form-input>
                    <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                  </validation-provider>
                </b-form-group>
                <a href="#" @click="selected_help_field = help_field.basement_volume"
                  class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                  <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                  <p class="m-0"><b>Guida alla compilazione</b></p>
                </a>
              </b-col>
            </b-row>

            <hr />

            <b-row>
              <b-col md="6">
                <b-form-group label="Tipologia Refrigerante">
                  <validation-provider #default="{ errors }" name="Tipologia Refrigerante" rules="required">
                    <b-input-group>
                      <b-form-input hidden id="Tipologia Refrigerante" v-model="refrigerant_type" readonly
                        :state="errors.length > 0 ? false : null" />

                      <b-form-input readonly :value="refrigerant_type.numero_refrigerante"></b-form-input>

                      <b-input-group-append>
                        <b-button variant="primary" @click="$bvModal.show('modal-refrigerant-type')"
                          v-html="refrigerant_type == '' ? 'Seleziona' : 'Modifica Selezione'"></b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Misure di Sicurezza -->

        <tab-content title="Misure di Sicurezza">
          <validation-observer ref="securityRules">

            <!-- Casistica 1 -->

            <fieldset class="border p-1 bg-light"
              v-if="project_user_role == 'Installatore' || project_user_role == 'Utilizzatore'">


              <b-row>
                <b-col md="12">
                  <h3>Misure di sicurezza adottate:</h3>
                </b-col>

                <b-col md="12">
                  <b-form-group label="In quali ambienti l'unità interna è montata ad un'altezza inferiore a 1,8m?">
                    <validation-provider #default="{ errors }" name="security_question_1_0">

                      <b-form-checkbox v-for="(cell, index) in cells" :key="'A' + cell.cell_id"
                        :value="{ cell_id: cell.cell_id, value: 'Yes' }"
                        :unchecked-value="{ cell_id: cell.cell_id, value: 'No' }"
                        v-model="security_question_1_0[index]">
                        {{ cell.cell_name }}</b-form-checkbox>

                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                  <a href="#" @click="selected_help_field = help_field.security_question_1_0"
                    class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                    <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                    <p class="m-0"><b>Guida alla compilazione</b></p>
                  </a>
                </b-col>

                <b-col md="12" v-for="(cell, index) in cells" :key="'B' + cell.cell_id">
                  <b-form-group v-if="security_question_1_0[index] && security_question_1_0[index].value == 'Yes'"
                    :label="'Nell\'ambiente ' + cell.cell_name + ' è presente un sistema di circolazione o ventilazione meccanica?'">
                    <validation-provider #default="{ errors }" name="security_question_1_1" rules="required">
                      <b-form-radio-group :id="'security_question_1_1_' + [index]"
                        v-model="security_question_1_1[index]" :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" :value="{ cell_id: cell.cell_id, value: 'Yes' }">Sì</b-form-radio>
                        <b-form-radio name="No" :value="{ cell_id: cell.cell_id, value: 'No' }">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Sono presenti aperture per favorire la ventilazione naturale?">
                    <validation-provider #default="{ errors }" name="security_question_1_2" rules="required">
                      <b-form-radio-group id="security_question_1_2" v-model="security_question_1_2"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                  <a href="#" @click="selected_help_field = help_field.security_question_1_2"
                    class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                    <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                    <p class="m-0"><b>Guida alla compilazione</b></p>
                  </a>
                </b-col>

                <b-col md="12" v-if="security_question_1_2 == 'Yes'">
                  <b-form-group label="In quali ambienti sono presenti tali aperture?">
                    <validation-provider #default="{ errors }" name="security_question_1_3">

                      <b-form-checkbox v-for="(cell, index) in cells" :key="'C' + cell.cell_id"
                        :value="{ cell_id: cell.cell_id, value: 'Yes' }"
                        :unchecked-value="{ cell_id: cell_id, value: 'No' }" v-model="security_question_1_3[index]">
                        {{ cell.cell_name }}</b-form-checkbox>

                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>

                </b-row>
                <b-row v-for="(cell, index) in cells" :key="'D' + cell.cell_id">
                <b-col md="4">
                  <b-form-group v-if="security_question_1_3[index] && security_question_1_3[index].value == 'Yes'"
                    :label="'Indicare l\'area delle aperture di diluizione dell\'ambiente ' + cell.cell_name">
                    <validation-provider #default="{ errors }" :name="'security_question_1_4_' + [index]" rules="required">
                      <b-form-input type="number" step="0.01" placeholder="Area [m2]" v-model="dummy2[index]"
                        @input="security_question_1_4[index] = { cell_id: cell.cell_id, value: $event }" />
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                  <a v-if="security_question_1_3[index] && security_question_1_3[index].value == 'Yes'" href="#"
                    @click="selected_help_field = help_field.security_question_1_4"
                    class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                    <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                    <p class="m-0"><b>Guida alla compilazione</b></p>
                  </a>
                  <br/>
                </b-col>
                </b-row>

                <b-row>
                <b-col md="12">
                  <b-form-group label="In quali ambienti è presente un sistema di ventilazione meccanica?">
                    <validation-provider #default="{ errors }" name="security_question_1_5">
                      <b-form-checkbox v-for="(cell, index) in cells" :key="'E' + cell.cell_id"
                        :value="{ cell_id: cell.cell_id, value: 'Yes' }"
                        :unchecked-value="{ cell_id: cell.cell_id, value: 'No' }"
                        v-model="security_question_1_5[index]">
                        {{ cell.cell_name }}</b-form-checkbox>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                  <a href="#" @click="selected_help_field = help_field.security_question_1_5"
                    class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                    <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                    <p class="m-0"><b>Guida alla compilazione</b></p>
                  </a>
                </b-col>
                </b-row>

                <b-row  v-for="(cell, index) in cells" :key="'F' + cell.cell_id">
                <b-col md="4">
                  <b-form-group v-if="security_question_1_5[index] && security_question_1_5[index].value == 'Yes'"
                    :label="'Indicare la portata del sistema di ventilazione dell\'ambiente ' + cell.cell_name">
                    <validation-provider #default="{ errors }" :name="'security_question_1_6_' + [index]" rules="required">
                      <b-form-input ref="portata_ventilazione" type="number" step="0.01" placeholder="[m3/h]" v-model="dummy[index]"
                        @input="security_question_1_6[index] = { cell_id: cell.cell_id, value: $event }" />
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                  <a v-if="security_question_1_5[index] && security_question_1_5[index].value == 'Yes'" href="#"
                    @click="selected_help_field = help_field.security_question_1_6"
                    class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                    <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                    <p class="m-0"><b>Guida alla compilazione</b></p>
                  </a>
                  <br/>
                </b-col>
                </b-row>

                <b-row>
                <b-col md="12">
                  <b-form-group label="Sono presenti valvole di intercettazione di sicurezza?">
                    <validation-provider #default="{ errors }" name="security_question_1_7" rules="required">
                      <b-form-radio-group id="security_question_1_7" v-model="security_question_1_7"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                  <a href="#" @click="selected_help_field = help_field.security_question_1_7"
                    class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                    <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                    <p class="m-0"><b>Guida alla compilazione</b></p>
                  </a>
                </b-col>

                <b-col md="12">
                  <b-form-group label="In quali ambienti sono presenti allarmi o dispositivi di sicurezza?">
                    <validation-provider #default="{ errors }" name="security_question_1_8">
                      <b-form-checkbox v-for="(cell, index) in cells" :key="'G' + cell.cell_id"
                        :value="{ cell_id: cell.cell_id, value: 'Yes' }"
                        :unchecked-value="{ cell_id: cell.cell_id, value: 'No' }"
                        v-model="security_question_1_8[index]">
                        {{ cell.cell_name }}</b-form-checkbox>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                  <a href="#" @click="selected_help_field = help_field.security_question_1_8"
                    class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                    <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                    <p class="m-0"><b>Guida alla compilazione</b></p>
                  </a>
                </b-col>

                <b-col md="12">
                  <b-form-group label="In quali ambienti sono presenti sistemi di rilevazione perdite?">
                    <validation-provider #default="{ errors }" name="security_question_1_9">
                      <b-form-checkbox v-for="(cell, index) in cells" :key="'H' + cell.cell_id"
                        :value="{ cell_id: cell.cell_id, value: 'Yes' }"
                        :unchecked-value="{ cell_id: cell.cell_id, value: 'No' }"
                        v-model="security_question_1_9[index]">
                        {{ cell.cell_name }}</b-form-checkbox>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                  <a href="#" @click="selected_help_field = help_field.security_question_1_9"
                    class="d-flex align-items-center text-primary cursor-pointer mb-1" v-b-modal.modal-info>
                    <feather-icon icon="InfoIcon" class="mr-1"></feather-icon>
                    <p class="m-0"><b>Guida alla compilazione</b></p>
                  </a>
                </b-col>

              </b-row>

            </fieldset>

            <!-- Casistica 2 -->

            <fieldset class="border p-1 bg-light mt-2" v-if="project_user_role == 'Installatore'">
              
              <b-row>

                <b-col md="12">
                  <b-form-group
                    label="La scelta dei materiali da utilizzare tiene conto della destinazione d'uso, delle perssioni di esercizio e dei parametri di resilienza?">
                    <validation-provider #default="{ errors }" name="security_question_2_1" rules="required">
                      <b-form-radio-group id="security_question_2_1" v-model="security_question_2_1"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="L'impianto è munito di vaso di espansione?">
                    <validation-provider #default="{ errors }" name="security_question_2_2" rules="required">
                      <b-form-radio-group id="security_question_2_2" v-model="security_question_2_2"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Le tubazioni e le parti di impianto sono protette da fonti di calore esterne?">
                    <validation-provider #default="{ errors }" name="security_question_2_3" rules="required">
                      <b-form-radio-group id="security_question_2_3" v-model="security_question_2_3"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Le parti d'impianto sono difficilmente raggiungibili dall'operatore?">
                    <validation-provider #default="{ errors }" name="security_question_2_4" rules="required">
                      <b-form-radio-group id="security_question_2_4" v-model="security_question_2_4"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="L'impianto è munito di pressostati e termostati con valore di set?">
                    <validation-provider #default="{ errors }" name="security_question_2_5" rules="required">
                      <b-form-radio-group id="security_question_2_5" v-model="security_question_2_5"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="L'impianto è munito di smorzatori e tubi antivibrazioni?">
                    <validation-provider #default="{ errors }" name="security_question_2_6" rules="required">
                      <b-form-radio-group id="security_question_2_6" v-model="security_question_2_6"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="L'impianto è munito di valvole di sicurezza?">
                    <validation-provider #default="{ errors }" name="security_question_2_7" rules="required">
                      <b-form-radio-group id="security_question_2_7" v-model="security_question_2_7"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>

            </fieldset>

            <fieldset class="border p-1 bg-light mt-2" v-if="project_user_role == 'Manutentore'">
             

              <!-- Casistica 3 -->

              <b-row>

                <b-col md="12">
                  <b-form-group
                    label="Il personale impiegato per le operazioni di manutenzione e supervisione è adeguatamente formato e istruito?">
                    <validation-provider #default="{ errors }" name="security_question_3_1" rules="required">
                      <b-form-radio-group id="security_question_3_1" v-model="security_question_3_1"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Sono previste attività di riparazione?">
                    <validation-provider #default="{ errors }" name="security_question_3_2" rules="required">
                      <b-form-radio-group id="security_question_3_2" v-model="security_question_3_2"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="È previsto il cambio di refrigerante?">
                    <validation-provider #default="{ errors }" name="security_question_3_3" rules="required">
                      <b-form-radio-group id="security_question_3_3" v-model="security_question_3_3"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="È prevista attività di recupero per riutilizzo nello stesso sistema?">
                    <validation-provider #default="{ errors }" name="security_question_3_4" rules="required">
                      <b-form-radio-group id="security_question_3_4" v-model="security_question_3_4"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="È prevista attività di recupero per riutilizzo in sistema simile?">
                    <validation-provider #default="{ errors }" name="security_question_3_5" rules="required">
                      <b-form-radio-group id="security_question_3_5" v-model="security_question_3_5"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="È prevista attività di trasferimento del fluido refrigerante recuperato?">
                    <validation-provider #default="{ errors }" name="security_question_3_6" rules="required">
                      <b-form-radio-group id="security_question_3_6" v-model="security_question_3_6"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="È prevista attività di conservazione del fluido refrigerante recuperato?">
                    <validation-provider #default="{ errors }" name="security_question_3_7" rules="required">
                      <b-form-radio-group id="security_question_3_7" v-model="security_question_3_7"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="È prevista attività di smaltimento del fluido refrigerante recuperato?">
                    <validation-provider #default="{ errors }" name="security_question_3_8" rules="required">
                      <b-form-radio-group id="security_question_3_8" v-model="security_question_3_8"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <slot name="label">La manipolazione del refrigerante è stata gestita secondo le prescrizioni di cui
                      all'Allegato C della UNI EN 378-4:2020 (<a target="_blank"
                        v-bind:href="STORAGE_URL + '/guida_alla_manipolazione_e_stoccaggio_di_refrigeranti.pdf'">Guida
                        alla manipolazione</a>)?</slot>
                    <validation-provider #default="{ errors }" name="security_question_3_9" rules="required">
                      <b-form-radio-group id="security_question_3_9" v-model="project.security_question_3_9"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è
                        richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="I manutentori sono provvisti di DPI necessari allo scopo, controllati e manutenuti come prescritto da normativa?">
                    <validation-provider #default="{ errors }" name="security_question_3_10" rules="required">
                      <b-form-radio-group id="security_question_3_10" v-model="security_question_3_10"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <slot name="label">Si dichiara di aver messo in campo tutte le azioni necessarie e propedeutiche
                      all'intervento come riportate nella <a target="_blank"
                        v-bind:href="STORAGE_URL + '/guida_per_le_riparazioni_di_apparecchiature_che_utilizzano_refrigeranti_infiammabili.pdf'">'Guida
                        per la riparazione di apparecchiature funzionanenti con refrigeranti infiammabili'</a>?</slot>
                    <validation-provider #default="{ errors }" name="security_question_3_11" rules="required">
                      <b-form-radio-group id="security_question_3_11" v-model="security_question_3_11"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <slot name="label">Prima e dopo l'intervento effettuato, sono state effettuate le ispezioni di
                      servizio come indicato in Allegato D della UNI EN 378-4:2020 (<a target="_blank"
                        v-bind:href="STORAGE_URL + '/guida_alle_ispezioni_in_servizio.pdf'">Guida alle ispezioni in
                        servizio</a>)?</slot>
                    <validation-provider #default="{ errors }" name="security_question_3_12" rules="required">
                      <b-form-radio-group id="security_question_3_12" v-model="security_question_3_12"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <slot name="label">Sono state verificate le prescrizioni di cui al paragrafo 5 della UNI EN
                      378-3:2021 (<a target="_blank"
                        v-bind:href="STORAGE_URL + '/check_list_controllo_sala_macchine.pdf'">Prescrizioni sala
                        macchine</a>)?</slot>
                    <validation-provider #default="{ errors }" name="security_question_3_13" rules="required">
                      <b-form-radio-group id="security_question_3_13" v-model="security_question_3_13"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

            </fieldset>

            <!-- Casistica 4 -->

            <fieldset class="border p-1 bg-light mt-2"
              v-if="project_user_role == 'Installatore' || project_user_role == 'Utilizzatore'">


              <b-row>

                <b-col md="12">
                  <b-form-group
                    label="I dispositivi di intercettazione manuali sono montati in un condotto di tubazioni o in un pozzo progettato per l'uso umano?">
                    <validation-provider #default="{ errors }" name="security_question_4_1" rules="required">
                      <b-form-radio-group id="security_question_4_1" v-model="security_question_4_1"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <slot name="label">Si dichiara di rispettare le prescrizioni relative alla sala macchine (<a
                        v-bind:href="STORAGE_URL + '/check_list_controllo_sala_macchine.pdf'" target="_blank">Checklist
                        controllo sala macchine</a>)?</slot>
                    <validation-provider #default="{ errors }" name="security_question_4_2" rules="required">
                      <b-form-radio-group id="security_question_4_2" v-model="security_question_4_2"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>



                </b-col>

                <b-col class="mt-2" md="12">
                  <b-form-group>
                    <slot name="label">Dopo l'intervento effettuato, sono state effettuate le ispezioni di servizio come
                      indicato in Allegato D della UNI EN 378-4:2020 (<a target="_blank"
                        v-bind:href="STORAGE_URL + '/guida_alle_ispezioni_in_servizio.pdf'">Guida alle ispezioni in
                        servizio</a>)</slot>
                    <validation-provider #default="{ errors }" name="security_question_4_3" rules="required">
                      <b-form-radio-group id="security_question_4_3" v-model="security_question_4_3"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>

                <b-col class="mt-2" md="12">
                  <b-form-group>
                    <slot name="label">Si dichiara di aver verificato la disponibilità di documenti come prescritto al
                      Capitolo 10 della UNI EN 378-3:2021 (<a target="_blank"
                        v-bind:href="STORAGE_URL + '/check_list_controllo_manuali_avvisi_e_ispezioni.pdf'">Manuale ed
                        istruzioni</a>)?</slot>
                    <validation-provider #default="{ errors }" name="security_question_4_4" rules="required">
                      <b-form-radio-group id="security_question_4_4" v-model="security_question_4_4"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col class="mt-2" md="12">
                  <b-form-group label="">
                    <slot name="label">Sono disponibili i DPI prescritti da normativa (<a target="_blank"
                        v-bind:href="STORAGE_URL + '/check_list_controllo_dpi.pdf'">Checklist controllo DPI</a>)?</slot>
                    <validation-provider #default="{ errors }" name="security_question_4_5" rules="required">
                      <b-form-radio-group id="security_question_4_5" v-model="security_question_4_5"
                        :state="errors.length > 0 ? false : null">
                        <b-form-radio name="Sì" value="Yes">Sì</b-form-radio>
                        <b-form-radio name="No" value="No">No</b-form-radio>
                      </b-form-radio-group>
                      <small v-if="errors.length > 0" class="text-danger">Il campo è richiesto</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>

            </fieldset>

          </validation-observer>


        </tab-content>

        <!-- Completato -->
        <tab-content title="Riepilogo">
          <div v-html="summary">
          </div>
        </tab-content>

        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-left">
            <b-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click="$refs.projectWizard.prevTab()">
              Indietro</b-button>
          </div>
          <div class="wizard-footer-right">
            <b-button variant="primary" v-if="!props.isLastStep" :disabled="loading"
              @click="props.activeTabIndex == 1 ? validationStep2OnNext() : (props.activeTabIndex == 3 ? validationFormSecurity() : $refs.projectWizard.nextTab())"
              class="wizard-footer-right">Avanti</b-button>

            <b-button @click="$router.push('/saved_projects')" v-else-if="props.activeTabIndex != 1" variant="primary"
              class="wizard-footer-right finish-button">{{ props.isLastStep ? "Vai ai Progetti Salvati" : "Avanti" }}
            </b-button>
          </div>
        </template>
      </form-wizard>
    </div>

    <!-- Modals -->

    <b-modal id="modal-1" title="Categoria di accesso" size="lg" hide-footer>
      <b-card-text>
        <b-table :items="access_categories" :fields="fields">
          <template #cell(categoria)="data">
            <p class="text-bold">{{ data.value }}</p>
          </template>

          <template #cell(caratteristiche_generali)="data">
            <div v-html="data.value"></div>
          </template>
        </b-table>
      </b-card-text>
    </b-modal>

    <b-modal id="modal-2" title="Allocazione parti di impianto" size="lg" hide-footer>
      <b-card-text>
        <b-table :items="allocation" :fields="allocation_fields">
          <template #cell(categoria)="data">
            <p class="text-bold">{{ data.value }}</p>
          </template>
        </b-table>
        <hr />

        <div class="d-flex justify-content-between">
          <div class="mr-3">
            <b>N.B.</b>
          </div>

          <div>
            Impianti e parte di impianti non devono essere posizionati/allocati lungo le vie di esodo,
            scalinate entrate o uscite di emergenza o comunque in nessun luogo dove possa limitare il passaggio delle
            persone
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <b-modal id="modal-3" title="Tipologia Impianto" size="lg" hide-footer>
      <b-card-text>
        <b-tabs>
          <b-tab title="Sistema a rilascio diretto">
            <b-table :items="direct_systems" :fields="typology_fields">
              <template #cell(definizione)="data">
                <p :class="data.item.bold ? 'font-weight-bolder' : ''">{{ data.value }}</p>
              </template>
              <template #cell(categoria)="data">
                <p class="text-bold">{{ data.value }}</p>
              </template>
              <template #cell(immagini)="data">
                <img :src="require('@/assets/images/sistemi/' + data.value)" />
              </template>
            </b-table>

            <hr />

            <div>

              <b>Legenda</b>
              <p>

              <ul style="list-style-type: none; margin:0; padding:0">
                <li>1 - Spazio Occupato</li>
                <li>2 - Parti Contenenti Refrigerante</li>
              </ul>

              </p>
            </div>
          </b-tab>
          <b-tab title="Sistema indiretto">
            <b-table :items="indirect_systems" :fields="typology_fields">
              <template #cell(definizione)="data">
                <p :class="data.item.bold ? 'font-weight-bolder' : ''">{{ data.value }}</p>
              </template>
              <template #cell(categoria)="data">
                <p class="text-bold">{{ data.value }}</p>
              </template>
              <template #cell(immagini)="data">
                <img :src="require('@/assets/images/sistemi/' + data.value)" />
              </template>
            </b-table>

            <hr />

            <div>

              <b>Legenda</b>
              <p>

              <ul style="list-style-type: none; margin:0; padding:0;">
                <li>1 - Spazio Occupato</li>
                <li>2 - Parti Contenenti Refrigerante</li>
                <li>P1 - Pressione 1</li>
                <li>P2 - Pressione 2</li>
              </ul>

              </p>
            </div>

          </b-tab>

        </b-tabs>

      </b-card-text>
    </b-modal>

    <b-modal id="modal-impianti" title="Denominazione Ambienti" size="lg" cancel-title="Annulla" ok-title="Conferma"
      @ok.prevent="validationFormNames">
      <validation-observer ref="nameRules">
        <b-card-text>
          <b-form-group v-for="i in cellNumber" :key="i" :label="'Denominazione Ambiente ' + i">
            <validation-provider :name="'Denominazione Ambiente ' + i" #default="{ errors }" rules="required">
              <b-form-input v-model="nameList[i - 1]"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-card-text>
      </validation-observer>
    </b-modal>

    <b-modal id="modal-refrigerant-type" title="Seleziona Refrigerante" size="xl" ok-only ok-title="Conferma" scrollable
      style="overflow-y: hidden">

      <vue-autosuggest v-model="refrigerant_type.numero_refrigerante" :suggestions="filteredOptions" :limit="10"
        :input-props="{ id: 'autosuggest__input', class: 'form-control', placeholder: 'Ricerca refrigerante' }"
        @input="onInputChange" @selected="onSelect">
        <template slot="before-input">
          <label>Cerca:</label>
        </template>
        <template slot-scope="{suggestion}">
          <span class="my-suggestion-item">{{ suggestion.item.numero_refrigerante }}</span>
        </template>
      </vue-autosuggest>

      <b-tabs style="max-height: 100%">
        <b-tab style="height: calc(100vh - 262px); overflow: auto" v-for="(gas, index) in refrigerantGases" :key="index"
          :title="Object.keys(refrigerantGases[index])[0]">
          <b-form-group>

            <b-form-radio-group stacked>
              <b-table :items="gas[Object.keys(refrigerantGases[index])]" :fields="['seleziona_refrigerante']">
                <template #cell(seleziona_refrigerante)="data">
                  <b-form-radio v-model="refrigerant_type" class="my-1" :value="data.item"
                    :disabled="data.item.record_status == 'inactive'">
                    {{ data.item.numero_refrigerante }}
                  </b-form-radio>
                </template>
              </b-table>
            </b-form-radio-group>

          </b-form-group>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal id="modal-error" title="Attenzione" size="lg" cancel-disabled ok-title="OK">
      <b-card-text>Ci sono dei campi non compilati.</b-card-text>
    </b-modal>

    <b-modal id="modal-conferma" title="Conferma Dati" size="lg" cancel-title="Annulla" ok-title="Conferma"
      @ok="validationFormSecurity">
      <validation-observer ref="nameRules">
        <b-card-text>Sei sicuro di voler procedere?</b-card-text>
      </validation-observer>
    </b-modal>

    <b-modal @hidden="selected_help_field = ''" id="modal-info" title="Guida alla compilazione" size="lg"
      cancel-disabled ok-only ok-title="OK">
      <b-card-text v-html="selected_help_field"></b-card-text>
    </b-modal>
  </section>
</template>

<script>

import { VueAutosuggest } from "vue-autosuggest";
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BTable,
  BAlert,
  BTabs,
  BTab,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BCardText,
  BButton,
  BButtonGroup,
  BInputGroup,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormInput,
  BFormFile,
  BFormSelect,
  BFormSelectOption,
  BFormSpinbutton,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { Requests } from "@/api/requests.js";

//Select Menu Data

import * as provinces from "@/assets/data/province.json";
import * as categories from "@/assets/data/categories.json";
import * as allocation_classes from "@/assets/data/allocation_classes.json";
import installation_typologies from "@/assets/data/installation_typologies.js";
import { STORAGE_URL } from '@/config';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    WizardStep,
    TabContent,
    BTable,
    BAlert,
    BTabs,
    BTab,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BCardText,
    BButton,
    BButtonGroup,
    BInputGroup,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormSelect,
    BFormSelectOption,
    BFormSpinbutton,
    BFormTextarea,
    BSpinner,
    vSelect,
    VueAutosuggest,
    BFormInvalidFeedback,
    ToastificationContent,
  },

  created() {
    localize("it");
    this.getRefrigerantGasList();
    this.getRefrigerantGasSuggestList();
    this.project_type = this.$route.query.project_type;
    if (!this.project_type) {
      const self = this;
      this.$swal("Attenzione", 'Si è verificato un errore interno', 'error').then(() => {
        self.$router.push("/");
      });
      return
    }
    if (['installatore', 'manutentore', 'utilizzatore'].includes(this.userData.account_type_code)) {
      this.project_user_role = this.userData.account_type_code[0].toUpperCase() + this.userData.account_type_code.substr(1);
    }
    if (this.userData.account_type_code == 'installatore_manutentore') {
      this.userRoles.pop();
    }
  },

  mounted() {
    if (this.project_type == '1') {
      this.$bvModal.show('new-project-new-preamble');
    } else if (this.project_type == '2') {
      if (this.userData.account_type_code == 'utilizzatore') {
        this.$bvModal.show('new-project-util-preamble');
      } else {
        this.$bvModal.show('new-project-maint-preamble');
      }
    }S
  },

  computed: {
    userData: function () {
      return JSON.parse(localStorage.getItem("user"));
    }
  },

  data: () => ({
    STORAGE_URL: STORAGE_URL,
    volume: [],
    summary: '',
    dummy: [],
    dummy2: [],
    project_id: null,

    allocation_fields: [
      "classe",
      "definizione",
      "caratteristiche_generali",
    ],
    typology_fields: [
      "definizione",
      "immagini",
    ],
    internal_unit_positions: [
      {
        value: "1",
        text: "Piano"
      },
      {
        value: "2",
        text: "Su parete"
      },
      {
        value: "3",
        text: "Su finestra"
      },
      {
        value: "4",
        text: "Soffitto"
      }
    ],
    fields: ["categoria", "livello_restrizione", "caratteristiche_generali", "esempi"],
    selected_help_field: "",
    help_field: {
      project_name: "Scegli un nome da assegnare al tuo progetto (Campo Facoltativo)",
      project_customer_companyname: "Inserire la ragione sociale del tuo cliente (Campo obbligatorio)",
      project_customer_vatnumber: "Inserire la P.IVA del tuo cliente (Campo obbligatorio)",
      project_customer_ceo_name: "Inserire il Nome del referente con cui ti sei interfacciato o il nome dell'Amministratore della società (Campo Facoltativo)",
      project_customer_ceo_surname: "Inserire il Cognome del referente con cui ti sei interfacciato o il nome dell'Amministratore della società (Campo Facoltativo)",
      project_customer_about: "Inserire una descrizione dell'attività svolta dal tuo cliente e l'eventuale storia. (Campo Facoltativo)",
      project_customer_desc_intervention: "Inserire una descrizione dell'intervento che si deve effettuare. (Campo Facoltativo).<br><br>N.B. all'interno del documento finale le informazioni da te inserite (parte in rosso) compariranno come segue:<br><br>L'intervento previsto riguarda l'attività di <span style=\"color: red\">“analisi dei rischi connessa all'utilizzo e manutenzione delle celle refrigerate“</span> da effettuarsi per conto della...",
      project_customer_type_intervention: "Inserisci la tipologia di intervento che si deve effettuare (Campo Facoltativo).<br><br>N.B. all'interno del documento finale le informazioni da te inserite (parte in rosso) compariranno come segue:<br><br>L'organizzazione ha fatto richiesta di <span style=\"color: red\">“consulenza in merito all'analisi di dettaglio circa i quantitativi massimi consentiti in conformità alla UNI EN 378 ed eventuale applicazione di misure di sicurezza aggiuntive“</span>",
      project_technical: "Inserisci i nominativi e referenze mail dei tecnici incaricati dalla tua azienda (Campo Facoltativo)",
      project_siteinspection: "Inserisce i dati relativi al sito oggetto di intervento / analisi (Campo obbligatorio)",
      project_implant_details: "Inserisci una breve descrizione dell'impianto da realizzare / realizzato (campo facoltativo)",
      project_implant_scope: "Seleziona lo scopo di utilizzo dell'impianto (Campo obbligatorio)",
      question_5: "selezionare il “SI” se è prevista almeno una delle seguenti attività: manutentiva o di riparazione su parte di impianto, riutilizzo o smaltimento del gas. (Campo obbligatorio)",
      question_0: "Indicare se l'impianto è a servizio di una pista di pattinaggio sul ghiaccio.",
      project_user_role: "Seleziona il ruolo che rivesti per questo progetto (Campo obbligatorio)",
      project_type: "Indicare in quale ambito si interviene (Campo obbligatorio)",
      envs_number: "Inserire il numero di ambienti con medesima categoria di accesso e asserviti da medesimo impianto  (Campo obbligatorio)",
      cell_surface: "Inserire l'estensione dell'ambiente specifico (Campo obbligatorio)",
      cell_height: "Inserire l'altezza media dell'ambiente specifico (Campo obbligatorio)",
      cell_temperature: "Inserire la temperatura prevista nell'ambiente. N.B. non necessari al fine dei calcoli, ma informazione che sarà riportata nella tabella riepilogativa degli ambienti. (Campo Facoltativo)",
      condenser_brand: "Inserire i dati relativi alla Marca dell'unità condensatrice. N.B. Se unica per tutti gli ambienti, ripetere le informazioni per ogni ambiente asservito (campo facoltativo)",
      condenser_model: "Inserire i dati relativi al Modello dell'unità condensatrice. N.B. Se unica per tutti gli ambienti, ripetere le informazioni per ogni ambiente asservito (campo facoltativo)",
      condenser_place: "Indicare in quale parte dell'edificio è installata l'unità. N.B. Se unica per tutti gli ambienti, indicare, oltre a dove è installata <em>\"comune a tutti gli ambienti\"</em>. (campo facoltativo)",
      exchanger_brand: "Inserire i dati relativi alla Marca dello scambiatore. N.B. Se unico per tutti gli ambienti, ripetere le informazioni per ogni ambiente asservito (campo facoltativo)",
      exchanger_model: "Inserire i dati relativi al Modello dello scambiatore. N.B. Se unico per tutti gli ambienti, ripetere le informazioni per ogni ambiente asservito (campo facoltativo)",
      exchanger_place: "Indicare in quale parte dell'edificio è installato lo scambiatore. N.B. Se unico per tutti gli ambienti, indicare, oltre a dove è installato <em>\"comune a tutti gli ambienti\"</em>. (campo facoltativo)",
      compressor_brand: "Inserire i dati relativi alla Marca del compressore. N.B. Se unico per tutti gli ambienti, ripetere le informazioni per ogni ambiente asservito (campo facoltativo)",
      compressor_model: "Inserire i dati relativi al Modello del compressore. N.B. Se unico per tutti gli ambienti, ripetere le informazioni per ogni ambiente asservito (campo facoltativo)",
      compressor_place: "Indicare in quale parte dell'edificio è installato il compressore. N.B. Se unico per tutti gli ambienti, indicare, oltre a dove è installato <em>\"comune a tutti gli ambienti\"</em>. (campo facoltativo)",
      evaporator_brand: "Inserire i dati relativi alla Marca dell'evaporatore. N.B. Se unico per tutti gli ambienti, ripetere le informazioni per ogni ambiente asservito (campo facoltativo)",
      evaporator_model: "Inserire i dati relativi al Modello dell'evaporatore. N.B. Se unico per tutti gli ambienti, ripetere le informazioni per ogni ambiente asservito (campo facoltativo)",
      evaporator_place: "Indicare in quale parte dell'edificio è installato l'evaporatore. N.B. Se unico per tutti gli ambienti, indicare, oltre a dove è installato <em>\"comune a tutti gli ambienti\"</em>. (campo facoltativo)",
      number_of_user: "Inserire il numero di utilizzatori previsto in contemporanea - Affollamento dell'ambiente. (Campo obbligatorio)",
      cell_question_3: "Indicare se sono presenti eventuali aperture di ventilazione e diluizione dell'aria (Campo obbligatorio)",
      cell_question_1: "Indicare se l'ambiente specifico si trova nel seminterrato dell'edificio (Campo obbligatorio)",
      cell_question_2: "indicare se l'ambiente specifico è provvisto di uscite di emergenza per gli occupanti (Campo obbligatorio)",
      cell_question_4: "Indicare se ci sono operatori che possono accedere alle celle frigo (Campo obbligatorio)",
      cell_question_5: "Indicare se l'impianto è di tipo ermetico (Campo obbligatorio)",
      cell_refrigrerant_quantity: "Indicare la carica di refrigerante relativa all'ambiente specifico (Campo obbligatorio)",
      building_question_1: "Indicare se nell'edificio oggetto di analisi è presente un seminterrato anche se non asservito da impianto. (Campo obbligatorio)",
      security_question_1_0: "Indicare l'ambiente in cui l'unità interna è montata ad un'altezza inferiore a 1,8 m dal piano di calpestio. Nel caso in tutti gli ambienti le unità siano montate ad un'altezza superiore, NON selezionare alcun ambiente",
      security_question_1_2: "Indicare l'eventuale presenza di grate di areazione, fessure, aperture o qualsivoglia sistema di comunicazione con ambiente esterno con il quale avviene un passaggio di aria per ventilazione naturale  (Campo obbligatorio)",
      security_question_1_4: "Indicare l'area complessiva delle aperture [m2] N.B. se sono presenti piu aperture, sommare le singole aree (Campo facoltativo)",
      security_question_1_5: "Indicare se sono presenti sistemi di ventilazione meccanica (ventilatori, estrattori, sistemi generici di aerazione) (Campo obbligatorio)",
      security_question_1_6: "Inserire la portata del sistema di ventilazione. N.B. è un dato di targa dell'eventuale ventilatore o sistema di aerazione. Acquisire l'informazione dalla documentazione tecnica dell'impianto, dal relativo progetto o dalla targa presente a bordo macchina (Campo facoltativo)",
      security_question_1_7: "Indicare se l'impianto è munito di valvola di intercettazione di sicurezza N.B. tale valvola interviene se viene rilevata una perdita, arrestando la circolazione del gas. La valvola va posta all'esterno dell'ambiente occupato. Tali sistemi vengono integrati con dispositivi di rilevazione. (Campo obbligatorio)",
      security_question_1_8: "Indicare in quali ambienti sono presenti sistemi di allarme o dispositivi di sicurezza contro le perdite. Se tutti gli ambienti sono privi di tali sistemi, non selezionare alcun ambiente. (Campo obbligatorio)",
      security_question_1_9: "Indicare in quali ambienti sono presenti sistemi di rilevazione perdite. Se tutti gli ambienti sono privi di tali sistemi, non selezionare alcun ambiente. (Campo obbligatorio)",
      security_question_2_0: "",
      security_question_2_1: "",
      security_question_2_2: "",
      security_question_2_3: "",
      security_question_2_4: "",
      security_question_2_5: "",
      security_question_2_6: "",
      security_question_2_7: "",
      security_question_3_0: "",
      security_question_3_1: "",
      security_question_3_2: "",
      security_question_3_3: "",
      security_question_3_4: "",
      security_question_3_5: "",
      security_question_3_6: "",
      security_question_3_7: "",
      security_question_4_0: "",
      security_question_4_1: "",
      security_question_4_2: "",
      security_question_4_3: "",
      security_question_4_4: "",
      security_question_4_5: "",
      security_question_4_6: "",
      security_question_4_7: "",
      prescription_respect: "",
      occupied_space: "Spazio in un edificio delimitato da pareti, pavimenti e soffitti e che è occupato da persone per un periodo significativo",
      limit_movement: "Esempio strutture sanitarie, di riabilitazione, ambienti generici dove gli utilizzatori non hanno possibilità di evacuare autonomamente",
      positioning: "",
      basement_volume: "Inserire il volume del seminterrato non asservito all'impianto"
    },
    allocation: [
      {
        classe: "IV",
        definizione: "Locale ventilato",
        caratteristiche_generali:
          "Se tutte le parti contenenti i gas refrigeranti sono allocate in un locale ventilato."
      },
      {
        classe: "III",
        definizione: "Sala macchine o all'aperto",
        caratteristiche_generali:
          "Se tutte le parti contenenti i gas refrigeranti sono allocate in una sala macchine all'aperto."
      },
      {
        classe: "II",
        definizione: "Compressori in sala macchine o all'aperto",
        caratteristiche_generali:
          "Se tutti i compressori e i serbatoi in pressione sono allocati in sala macchine o all'aperto, a meno che non siano richiesti requisiti appartenenti alla classe III. Serpentine e piping possono essere allocate nell'ambiente occupato dalle persone",
      },
      {
        classe: "I",
        definizione:
          "Componenti di impianto allocati nell'ambiente occupato",
        caratteristiche_generali:
          "Se il sistema di refrigerazione o le sue parti contenenti gas refrigerante sono allocate nell'ambiente occupato",
      },
    ],



    implant_scope_items: [
      {
        value: null,
        text: 'Seleziona...',
        disabled: true,
      },
      {
        value: "Refrigerazione",
        text: 'Refrigerazione',
      },
      {
        value: "Condizionamento",
        text: "Condizionamento",
      }
    ],

    access_categories_items: categories.default,
    allocation_items: allocation_classes.default,
    installation_typologies: installation_typologies.installation_typologies,
    installation_type_items: [
      {
        value: null,
        text: "Seleziona...",
        disabled: true,
      },
    ],

    access_categories: [
      {
        categoria: "A",
        livello_restrizione: "3",
        caratteristiche_generali:
          "<p>Stanze o parti di edifici dove: </br> - sono forniti servizi per il pernottamento </br> - è limitata la libertà di movimento </br> - sono presenti un numero di persone incontrollato </br> - tutti quelli che accedono non hanno una preparazione adeguata circa i rischi e le precauzioni da adottare</p>",
        esempi:
          "Ospedali, Tribunali, Carceri, Teatri, Supermercati, Scuole, Sale Lettura, Capolinea Trasporti Pubblici, Hotel, Alloggi, Ristoranti",
      },
      {
        categoria: "B",
        livello_restrizione: "2",
        caratteristiche_generali:
          "<p>Stanze o parti di edifici o edifici dove: </br> - solo un numero limitato di persone possono assembrarsi </br> - dove almeno qualche persona è a conoscenza delle regole generali di sicurezza</p>",
        esempi:
          "Uffici, Aziende, Laboratori, Imprese Manufatturiere ed in generale luoghi dove la gente lavora",
      },
      {
        categoria: "C",
        livello_restrizione: "1",
        caratteristiche_generali:
          "<p>Stanze o parti di edifici o edifici dove: </br> - solo persone autorizzate possono avere accesso le quali sono a conoscenza dei rischi e delle precauzioni generali e specifiche da adottare per la sicurezza </br> - hanno luogo le attività di produzione o conservazione dei materiali o prodotti</p>",
        esempi:
          "Stabilimenti produttivi e nello specifico stabilimenti chimici, Alimentari, Bevande, Ghiaccio, Gelaterie, Impianti di Raffinazione, Celle Frigorifere, Caseifici, Mattatoi, Aree Riservate nei Supermercati",
      },
    ],

    direct_systems: [
      {
        bold: true,
        definizione: "Sistema diretto",
        caratteristiche_generali:
          "Un sistema diretto è classificato come sistema a rilascio diretto se una singola rottura del circuito di contenimento del refrigerante provoca il rilascio di refrigerante nello spazio di area occupata, indipendentemente dalla posizione del circuito frigorifero",
        immagini: "sistema-diretto.png",
        classe_di_esposizione: "Classe di ubicazione I (5.3 d) o II (5.3 c)",
      },
      {
        definizione: "Sistema aperto a spray",
        caratteristiche_generali:
          "Un sistema a spruzzo aperto deve essere classificato come sistema a rilascio diretto se il fluido termovettore è a diretto contatto con le parti contenenti refrigerante e il circuito indiretto è aperto a uno spazio occupato.",
        immagini: "sistema-aperto-a-spray.png",
        classe_di_esposizione: "Classe di ubicazione I (5.3 d) o II (5.3 c)",
      },
      {
        bold: true,
        definizione: "Sistema canalizzato",
        caratteristiche_generali:
          "Un sistema canalizzato è classificato come sistema a rilascio diretto se l'aria condizionata è in diretta contatto con le parti del circuito contenenti refrigerante e con l'aria condizionata fornita da uno spazio occupato.",
        immagini: "sistema-canalizzato.png",
        classe_di_esposizione: "Classe di ubicazione I (5.3 d) o II (5.3 c)",
      },
      {
        definizione: "Sistema di nebulizzazione a sfiato",
        caratteristiche_generali:
          "Un sistema di nebulizzazione a sfiato aperto è classificato come sistema a rilascio diretto se il fluido termovettore  a diretto contatto con le parti del circuito contenenti refrigerante e il circuito indiretto è aperto a uno spazio occupato. Il fluido termovettore deve essere sfogato nell'atmosfera al di fuori dello spazio occupato ma rimane la possibilità che la singola rottura del circuito frigorifero potrebbe provocare il rilascio di refrigerante al spazio occupato",
        immagini: "sistema-di-nebulizzazione-a-sfiato.png",
        classe_di_esposizione: "Classe di ubicazione I (5.3 d) o II (5.3 c)",
      },
    ],

    indirect_systems: [
      {
        bold: true,
        definizione: "Sistema indiretto chiuso",
        caratteristiche_generali:
          "Un sistema indiretto è classificato come sistema chiuso indiretto se il trasferimento del fluido cado  è in comunicazione diretta con uno spazio occupato e una perdita di refrigerante nell'indiretto circuito può entrare nello spazio occupato se anche il circuito indiretto perde o viene spurgato.",
        immagini: "sistema-indiretto-chiuso.png",
        classe_di_esposizione: "Classe di ubicazione I (5.3 d) o II (5.3 c)",
      },
      {
        bold: true,
        definizione: "Sistema indiretto con lo sfiato",
        caratteristiche_generali:
          "Un  sistema inadeguato deve essere classificato come un sistema ventilato inadeguato se il fluido termovettore è in comunicazione diretta con uno spazio occupato e una perdita di refrigerante nel circuito indiretto può sfociare nell'atmosfera al di fuori dello spazio occupato.",
        immagini: "sistema-indiretto-con-lo-sfiato.png",
        classe_di_esposizione: "Classe di ubicazione III (5.3 b) ",
      },
      {
        bold: true,
        definizione: "Sistema indiretto chiuso con lo sfiato",
        caratteristiche_generali:
          "Un sistema indiretto è classificato come sistema chiuso con sfiato indiretto se il fluido termovettore è in comunicazione diretta con uno spazio occupato e una perdita di refrigerante nel circuito indiretto può sfogare nell'atmosfera attraverso uno sfiato meccanico, all'esterno dello spazio occupato",
        immagini: "sistema-indiretto-chiuso-con-lo-sfiato.png",
        classe_di_esposizione: "Classe di ubicazione III (5.3 b) ",
      },
      {
        definizione: "Sistema indiretto doppio",
        caratteristiche_generali:
          "Un sistema indiretto è classificato come doppio sistema indiretto se il trasferimento del fluido caldo  è in comunicazione diretta con le parti contenenti refrigerante e il calore può essere scambiato con un secondo circuito indiretto che passa in uno spazio occupato. La perdita di refrigerante non può entrare nello spazio occupato.",
        immagini: "sistema-indiretto-doppio.png",
        classe_di_esposizione: "Classe di ubicazione III (5.3 b) ",
      },
      {
        definizione: "Sistema indiretto ad alta pressione",
        caratteristiche_generali:
          "Un sistema indiretto deve essere classificato come sistema indiretto ad alta pressione se il fluido termovettore è in comunicazione diretta con uno spazio occupato e il circuito indiretto è mantenuto sempre ad una pressione superiore a quella del circuito frigorifero in modo che una rottura del circuito frigorifero non può comportare un rilascio di refrigerante nello spazio occupato.  Il refrigerante non può fuoriuscire nel circuito indiretto.",
        immagini: "sistema-indiretto-ad-alta-pressione.png",
        classe_di_esposizione: "Classe di ubicazione III (5.3 b) ",
      },
    ],

    userRoles: [
      {
        value: null,
        text: "Seleziona...",
        disabled: true,
      },
      {
        value: "Installatore",
        text: "Installatore",
      },
      {
        value: "Manutentore",
        text: "Manutentore",
      },
      {
        value: "Utilizzatore",
        text: "Utilizzatore",
      }
    ],

    implantTypes: [
      {
        value: null,
        text: "Seleziona...",
        disabled: true,
      },
      {
        value: 1,
        text: "Impianto esistente",
      },
      {
        value: 2,
        text: "Da realizzare con progetto disponibile",
      },
    ],


    provinces: provinces.default,

    refrigerantGases: [],
    refrigerantGasesSuggest: [],
    filteredOptions: [],

    refrigerantGasesNumber: [],

    project_name: "",
    project_code: "",
    project_customer_companyname: '',
    project_customer_vatnumber: '',
    project_customer_ceo_name: '',
    project_customer_ceo_surname: '',
    project_customer_logo: null,
    project_customer_logo_path: '',
    project_customer_logo_url: '',
    project_building_photo: null,
    project_building_photo_path: '',
    project_building_photo_url: '',
    project_customer_address: '',
    project_customer_city: '',
    project_customer_zipcode: '',
    project_customer_province: '',
    project_customer_country: '',
    project_siteinspection: "No",
    project_siteinspection_date: '',
    project_customer_about: '',
    project_customer_desc_intervention: '',
    project_customer_type_intervention: '',
    project_technical_1_name: '',
    project_technical_1_surname: '',
    project_technical_1_email: '',
    project_technical_2_name: '',
    project_technical_2_surname: '',
    project_technical_2_email: '',
    project_siteinspection_address: '',
    project_siteinspection_city: '',
    project_siteinspection_zipcode: '',
    project_siteinspection_province: '',
    project_siteinspection_country: '',
    project_siteinspection_photo_1: null,
    project_siteinspection_photo_1_path: '',
    project_siteinspection_photo_1_url: '',
    project_siteinspection_photo_2: null,
    project_siteinspection_photo_2_path: '',
    project_siteinspection_photo_2_url: '',
    project_siteinspection_attachment: null,
    project_siteinspection_attachment_path: '',
    project_siteinspection_attachment_url: '',
    project_implant_details: '',
    project_implant_scope: null,
    project_user_role: null,
    project_type: null,
    project_access_category: null,
    project_plant_allocation: null,
    project_plant_type: null,
    question_0: '',
    question_1: '',
    question_2: '',
    question_3: '',
    question_4: '',
    question_5: 'No',
    question_6: '',
    question_7: '',
    question_8: '',
    refrigerant_quantity: 1,
    refrigerant_type: '',
    security_question_1_0: [],
    security_question_1_1: [],
    security_question_1_2: '',
    security_question_1_3: [],
    security_question_1_4: [],
    security_question_1_5: [],
    security_question_1_6: [],
    security_question_1_7: '',
    security_question_1_8: [],
    security_question_1_9: [],
    security_question_2_1: '',
    security_question_2_2: '',
    security_question_2_3: '',
    security_question_2_4: '',
    security_question_2_5: '',
    security_question_2_6: '',
    security_question_2_7: '',
    security_question_3_1: '',
    security_question_3_2: '',
    security_question_3_3: '',
    security_question_3_4: '',
    security_question_3_5: '',
    security_question_3_6: '',
    security_question_3_7: '',
    security_question_3_8: '',
    security_question_3_9: '',
    security_question_3_10: '',
    security_question_3_11: '',
    security_question_3_12: '',
    security_question_3_13: '',
    security_question_4_1: '',
    security_question_4_2: '',
    security_question_4_3: '',
    security_question_4_4: '',
    security_question_4_5: '',
    refrigerant_type: '',
    basement_volume: '',
    uploadedFile: '',

    cells: [],

    required,
    email,
    cellNumber: 0,
    cellsToGenerate: 0,
    nameList: [],
    tabIndex: 1,
    isValidate: false,
    disabled: false,

    //Helpers

    uploadLogoLoading: false,
    uploadBuildingPhotoLoading: false,
    uploadPhoto1Loading: false,
    uploadPhoto2Loading: false,
    uploadAttachmentLoading: false,
    loading: false,
  }),

  methods: {

    changeInstallationTypeItems() {
      switch (this.project_plant_allocation) {
        case '1':
        case '2':
          this.installation_type_items = this.installation_typologies.type_1
          break;

        case '3':
          this.installation_type_items = this.installation_typologies.type_2
          break;

        case '4':
          this.installation_type_items = this.installation_typologies.all
          break;
      }
    },

    async createProject() {
      this.loading = true;

      let data = new FormData();

      data.append('project_name', this.project_name)
      data.append('project_customer_companyname', this.project_customer_companyname)
      data.append('project_customer_vatnumber', this.project_customer_vatnumber)
      data.append('project_customer_ceo_name', this.project_customer_ceo_name)
      data.append('project_customer_ceo_surname', this.project_customer_ceo_surname)
      data.append('project_customer_logo_path', this.project_customer_logo_path)
      data.append('project_customer_splashimage_path', this.project_building_photo_path)
      data.append('project_customer_address', this.project_customer_address)
      data.append('project_customer_city', this.project_customer_city)
      data.append('project_customer_zipcode', this.project_customer_zipcode)
      data.append('project_customer_province', this.project_customer_province)
      data.append('project_customer_country', this.project_customer_country)
      data.append('project_siteinspection', this.project_siteinspection)
      data.append('project_siteinspection_date', this.$moment(Date.parse(this.project_siteinspection_date)).format('YYYY-MM-DD'))
      data.append('project_customer_about', this.project_customer_about)
      data.append('project_customer_desc_intervention', this.project_customer_desc_intervention)
      data.append('project_customer_type_intervention', this.project_customer_type_intervention)
      data.append('project_technical_1_name', this.project_technical_1_name)
      data.append('project_technical_1_surname', this.project_technical_1_surname)
      data.append('project_technical_1_email', this.project_technical_1_email)
      data.append('project_technical_2_name', this.project_technical_2_name)
      data.append('project_technical_2_surname', this.project_technical_2_surname)
      data.append('project_technical_2_email', this.project_technical_2_email)
      data.append('project_siteinspection_address', this.project_siteinspection_address)
      data.append('project_siteinspection_city', this.project_siteinspection_city)
      data.append('project_siteinspection_zipcode', this.project_siteinspection_zipcode)
      data.append('project_siteinspection_province', this.project_siteinspection_province)
      data.append('project_siteinspection_country', this.project_siteinspection_country)
      data.append('project_siteinspection_photo_1_path', this.project_siteinspection_photo_1_path)
      data.append('project_siteinspection_photo_2_path', this.project_siteinspection_photo_2_path)
      data.append('project_siteinspection_attachment_path', this.project_siteinspection_attachment_path)
      data.append('project_implant_details', this.project_implant_details)
      data.append('project_implant_scope', this.project_implant_scope)
      data.append('project_user_role', this.project_user_role)
      data.append('project_type', this.project_type)
      data.append('project_access_category', this.project_access_category)
      data.append('project_plant_allocation', this.project_plant_allocation)
      data.append('project_plant_type', this.project_plant_type)
      data.append('project_refrigerated_rooms', this.cellNumber)
      data.append('question_0', this.question_0)
      data.append('question_1', this.question_1)
      data.append('basement_volume', this.basement_volume)
      data.append('question_2', this.question_2)
      data.append('question_3', this.question_3)
      data.append('question_4', this.question_4)
      data.append('question_5', this.question_5)
      data.append('question_6', this.question_6)
      data.append('question_7', this.question_7)
      data.append('question_8', this.question_8)
      data.append('security_question_1_0', JSON.stringify(this.security_question_1_1) || '')
      data.append('security_question_1_1', JSON.stringify(this.security_question_1_1) || '')
      data.append('security_question_1_2', this.security_question_1_2 || '')
      data.append('security_question_1_3', JSON.stringify(this.security_question_1_3) || '')
      // let sec_quest_1_4 = [];
      // for(let i = 0; i < this.project.project_refrigerated_rooms_details.length; ++i) {
      //   if(this.project.security_question_1_3[i] && this.project.security_question_1_3[i].value == 'Yes') {
      //     sec_quest_1_4.push({cell_id: this.project.security_question_1_3[i].cell_id, value: this.project.security_question_1_4[i]});
      //   }
      // }
      data.append('security_question_1_4', JSON.stringify(this.security_question_1_4) || '[]')
      data.append('security_question_1_5', JSON.stringify(this.security_question_1_5) || '');
      // let sec_quest_1_6 = [];
      // for(let i = 0; i < this.project.project_refrigerated_rooms_details.length; ++i) {
      //   if(this.project.security_question_1_5[i] && this.project.security_question_1_5[i].value == 'Yes') {
      //     sec_quest_1_6.push({cell_id: this.project.security_question_1_5[i].cell_id, value: this.project.security_question_1_6[i]});
      //   }
      // }
      data.append('security_question_1_6', JSON.stringify(this.security_question_1_6) || '[]')
      data.append('security_question_1_7', this.security_question_1_7 || '')
      data.append('security_question_1_8', JSON.stringify(this.security_question_1_8) || '')
      data.append('security_question_1_9', JSON.stringify(this.security_question_1_9) || '')
      data.append('security_question_2_1', this.security_question_2_1 || '')
      data.append('security_question_2_2', this.security_question_2_2 || '')
      data.append('security_question_2_3', this.security_question_2_3 || '')
      data.append('security_question_2_4', this.security_question_2_4 || '')
      data.append('security_question_2_5', this.security_question_2_5 || '')
      data.append('security_question_2_6', this.security_question_2_6 || '')
      data.append('security_question_2_7', this.security_question_2_7 || '')
      data.append('security_question_3_1', this.security_question_3_1 || '')
      data.append('security_question_3_2', this.security_question_3_2 || '')
      data.append('security_question_3_3', this.security_question_3_3 || '')
      data.append('security_question_3_4', this.security_question_3_4 || '')
      data.append('security_question_3_5', this.security_question_3_5 || '')
      data.append('security_question_3_6', this.security_question_3_6 || '')
      data.append('security_question_3_7', this.security_question_3_7 || '')
      data.append('security_question_3_8', this.security_question_3_8 || '')
      data.append('security_question_3_9', this.security_question_3_9 || '')
      data.append('security_question_3_10', this.security_question_3_10 || '')
      data.append('security_question_3_11', this.security_question_3_11 || '')
      data.append('security_question_3_12', this.security_question_3_12 || '')
      data.append('security_question_3_13', this.security_question_3_13 || '')
      data.append('security_question_4_1', this.security_question_4_1 || '')
      data.append('security_question_4_2', this.security_question_4_2 || '')
      data.append('security_question_4_3', this.security_question_4_3 || '')
      data.append('security_question_4_4', this.security_question_4_4 || '')
      data.append('security_question_4_5', this.security_question_4_5 || '')
      data.append('refrigerant_type', this.refrigerant_type.id_refrigerant)
      data.append('project_refrigerated_rooms_details_json', JSON.stringify(this.cells))

      try {
        const response = await Requests.createProject(data);
        console.log(response)
        if (response.project_addnew.success == "true") {
          this.project_id = response.project_addnew.id_project;
          await this.getProjectSummary(this.project_id);
          
          this.$refs.projectWizard.nextTab();
        } else {
          alert('Si è verificato un problema durante la creazione del progetto')
        }
      } catch (err) {
        console.log(err)
      }

      this.loading = false;
    },

    async getProjectSummary(id) {

      let request_data = new FormData();

      request_data.append('id', id);

      try {
        const response = await Requests.getProjectSummary(request_data);
        console.log(response);
        this.summary = response;
      } catch (err) {
        console.log(err)
      }
    },

    generateForm() {
      if (this.cells.length > 0 && this.cellNumber != this.cells.length) {
        if (this.cellNumber > this.cells.length) {
          for (let i = this.cells.length; i < this.cellNumber; i++) {
            this.cells.push({
              cell_id: i + 1,
              cell_name: this.nameList[i],
              cell_surface: null,
              cell_height: null,
              cell_volume: null,
              cell_temperature: '',
              condenser_brand: '',
              condenser_model: '',
              condenser_place: '',
              exchanger_brand: '',
              exchanger_model: '',
              exchanger_place: '',
              compressor_brand: '',
              compressor_model: '',
              compressor_place: '',
              evaporator_brand: '',
              evaporator_model: '',
              evaporator_place: '',
              number_of_user: 1,
              refrigerant_quantity: 1,
              question_1: '',
              question_2: '',
              question_3: '',
              question_4: 'Yes',
              question_5: 'No',
              question_6: '',
              question_7: '',
              question_8: '',
              positioning: '',
            })
            this.security_question_1_0.push({ cell_id: i + 1, value: 'No' })
            this.security_question_1_3.push({ cell_id: i + 1, value: 'No' })
            this.security_question_1_5.push({ cell_id: i + 1, value: 'No' })
            this.security_question_1_6.push({ cell_id: i + 1, value: '' })
          }
        } else {
          this.cells.splice(this.cellNumber);
          this.nameList.splice(this.cellNumber);
          this.security_question_1_0.splice(this.cellNumber);
          this.security_question_1_3.splice(this.cellNumber);
          this.security_question_1_5.splice(this.cellNumber);
          this.security_question_1_6.splice(this.cellNumber);
        }
      } else {
        const newCells = [];
        for (let i = 0; i < this.cellNumber; i++) {
          newCells.push({
            cell_id: i + 1,
            cell_name: this.nameList[i],
            cell_surface: null,
            cell_height: null,
            cell_volume: null,
            cell_temperature: '',
            condenser_brand: '',
            condenser_model: '',
            condenser_place: '',
            exchanger_brand: '',
            exchanger_model: '',
            exchanger_place: '',
            compressor_brand: '',
            compressor_model: '',
            compressor_place: '',
            evaporator_brand: '',
            evaporator_model: '',
            evaporator_place: '',
            number_of_user: 1,
            refrigerant_quantity: 1,
            question_1: '',
            question_2: '',
            question_3: '',
            question_4: 'Yes',
            question_5: 'Yes',
            question_6: '',
            question_7: '',
            question_8: '',
            positioning: ''
          })
          this.security_question_1_0.push({ cell_id: i + 1, value: 'No' })
          this.security_question_1_3.push({ cell_id: i + 1, value: 'No' })
          this.security_question_1_5.push({ cell_id: i + 1, value: 'No' })
        }
        this.cells = newCells;
      }
      this.cellsToGenerate = this.cellNumber;

    },

    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Progetto Creato",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },

    validationCustomer() {
      return new Promise((resolve, reject) => {
        this.$refs.customerTab.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            alert('Verificare tutti i dati inseriti');
            reject();
          }
        });
      });
    },

    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {

            resolve(true);
          } else {
            alert('Verificare tutti i dati inseriti');
            reject();
          }
        });
      });
    },

    validationFormDescription2() {
      return new Promise((resolve, reject) => {
        this.$refs.description2Rules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            alert('Verificare tutti i dati inseriti');
            reject();
          }
        });
      });
    },

    validationFormSecurity() {
      return new Promise((resolve, reject) => {
        this.$refs.securityRules.validate().then((success) => {
          if (success) {
            this.createProject()
            this.tabIndex++;
            resolve(true);
          } else {
            alert('Verificare tutti i dati inseriti');
            reject();
          }
        });
      });
    },

    validationFormTabs(i) {
      return new Promise((resolve, reject) => {
        this.$refs.tabRules[i].validate().then((success) => {
          if (success) {
            this.tabIndex++;
            resolve(true);
          } else {
            alert('Verificare tutti i dati inseriti');
            reject();
          }
        });
      });
    },

    validationFormNames() {
      return new Promise((resolve, reject) => {
        this.$refs.nameRules.validate().then((success) => {
          if (success) {
            this.generateForm();
            this.$bvModal.hide("modal-impianti");
            this.disabled = true;
            resolve(true);
          } else {
            alert('Verificare tutti i dati inseriti');
            reject();
          }
        });
      });
    },

    validationStep2OnNext() {
      debugger;
      if (
        this.cellNumber === 0
        || this.nameList.length !== this.cellNumber
        || this.nameList.some(name => !name || name === '')
        || this.cells.length != this.cellNumber
      ) {
        alert('Verificare il numero e i dati degli ambienti');
      } else {
        this.$refs.projectWizard.nextTab()
      }
    },

    async getRefrigerantGasList() {
      try {
        const response = await Requests.getRefrigerantGasList();
        for (let gas in response.data) {
          this.refrigerantGases = response.data;
        }
      } catch (err) {
        console.log(err)
      }
    },

    async getRefrigerantGasSuggestList() {
      try {
        const response = await Requests.getRefrigerantGasSuggestList();
        this.refrigerantGasesSuggest = response;
      } catch (err) {
        console.log(err)
      }
    },

    async uploadLogo(data) {

      this.uploadLogoLoading = true;

      let formData = new FormData()

      formData.append('Filedata', data)

      try {
        const response = await Requests.uploadImage(formData);
        this.project_customer_logo_path = response.data;
        this.project_customer_logo_url = URL.createObjectURL(data)

      } catch (err) {
        console.log(err)
      }

      this.uploadLogoLoading = false;
    },

    async uploadBuildingPhoto(data) {

      this.uploadBuildingPhotoLoading = true;

      let formData = new FormData()

      formData.append('Filedata', data)

      try {
        const response = await Requests.uploadImage(formData);
        this.project_building_photo_path = response.data;
        this.project_building_photo_url = URL.createObjectURL(data)

      } catch (err) {
        console.log(err)
      }

      this.uploadBuildingPhotoLoading = false;
    },

    async uploadPhoto1(data) {

      this.uploadPhoto1Loading = true;

      let formData = new FormData()

      formData.append('Filedata', data)

      try {
        const response = await Requests.uploadImage(formData);
        this.project_siteinspection_photo_1_path = response.data;
        this.project_siteinspection_photo_1_url = URL.createObjectURL(data);

      } catch (err) {
        console.log(err)
      }

      this.uploadPhoto1Loading = false;
    },

    async uploadPhoto2(data) {

      this.uploadPhoto2Loading = true;

      let formData = new FormData()

      formData.append('Filedata', data)

      try {
        const response = await Requests.uploadImage(formData);
        this.project_siteinspection_photo_2_path = response.data;
        this.project_siteinspection_photo_2_url = URL.createObjectURL(data);

      } catch (err) {
        console.log(err)
      }

      this.uploadPhoto2Loading = false;
    },

    async uploadAttachment(data) {

      this.uploadAttachmentLoading = true;

      let formData = new FormData()

      formData.append('Filedata', data)

      try {
        const response = await Requests.uploadImage(formData);
        this.project_siteinspection_attachment_path = response.data;
        this.project_siteinspection_attachment_url = URL.createObjectURL(data);

      } catch (err) {
        console.log(err)
      }

      this.uploadAttachmentLoading = false
    },

    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }


      const filteredData = this.refrigerantGasesSuggest.filter(item => item.numero_refrigerante.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)

      this.filteredOptions = [{
        data: filteredData,
      }]
    },

    onSelect(item) {
      this.$bvModal.hide("modal-refrigerant-type");
      this.refrigerant_type = item.item
    },
  },

};
</script>

<style>
.nav-tabs .nav-link {
  justify-content: start !important;
}

.disabledTab {
  pointer-events: none !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
